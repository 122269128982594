<template>
    <Panel header="K9 Service" v-model:collapsed="k9_collapsed" :collapsed="true" :toggleable="true" @toggle="fill_k9_service($event)">
        <template #header>
            <div @click="k9_collapsed = !k9_collapsed; fill_k9_service($event)" class="col" style="padding: 0.7rem;">
                <div class="row"  >
                    <div class="col">
                        <span class="title_panels"> K9  </span>
                    </div>
                </div>
            </div>
        </template>
        <template #icons>
            <i v-if="created_automatically" v-tooltip.left="'This service was created automatically with previous data.'" class="fa-solid fa-circle-info fa-xl" style="color: #fcc203;"></i>    
            <i v-if="k9_services.status" class="p-panel-header-icon mr-2">
                <span v-if="k9_services.status === 'None'">
                    <span v-tooltip.left="'Service email not sent.'" class="fa fa-circle-half-stroke fa-xl" style="color: #002d7d;"></span>
                </span>
                <span v-if="k9_services.status === 'Modified'">
                    <span v-tooltip.left="'Service modified after email.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: orange;"></span>
                </span>
                <span v-if="k9_services.status === 'On hold'">
                    <span v-tooltip.left="'Service email sent and waiting for confirmation.'" class="fa fa-circle fa-xl" style="color: orange;"></span>
                </span>
                <span v-if="k9_services.status === 'Confirmed'">
                    <span v-tooltip.left="'Service email confirmed.'" class="fa fa-circle fa-xl" style="color: #22C55E;"></span>
                </span>
                <span v-if="k9_services.status === 'Refused'">
                    <span v-tooltip.left="'Service email refused.'" class="fa fa-circle fa-xl" style="color: #EF4444;"></span>
                </span>
            </i>
        </template>
        <div class="container-fluid p-fluid">
            <div class="row">
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Supplier </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="all_suppliers" :disabled="!$ability.can('change','k9') || check_user_service_operator" :filter="true" optionLabel="name" v-model="k9_services.id_supplier" @change="load_k9_service_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span> {{slotProps.value.name}} </span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Responsible </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="k9_service_responsibles_list" placeholder="Select a responsible..." :disabled="!$ability.can('change','k9') || check_user_service_operator" :filter="true" optionLabel="name" v-model="k9_services.id_supplier_responsible" @click="load_k9_service_responsibles()"> 
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$ability.can('add','supplier_responsible')" class="row justify-content-end">
                <div class="col-12 md:col-3 text-center">
                    <ModalSupplierResponsibles :service_sheet="true"/>
                </div>
            </div>
             <Divider align="center" class="mt-2 mb-3">
                <div class="inline-flex align-items-center">
                    <b> Shifts </b>
                </div>
            </Divider>
            <TabView @tab-click="handleTabChange">
                <TabPanel header="Estimation">
                    <div v-if="k9_services.status == 'Pending'">
                        <Message :closable="false" severity="info"> The email of this service has already been sent, if any modifications are made, the previous email will be annulled. </Message>
                    </div>
                    <div v-if="k9_services.status == 'Confirmed'">
                        <Message :closable="false" severity="info"> This service has already been confirmed. If any modifications are made, the status will change, and the previous email will be annulled. </Message>
                    </div>
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        <DataTable :value="get_k9_services_estimated" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_k9" @row-edit-save="onRowEditSaveK9">
                            <Column header="Quantity" field="quantity" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Type" field="id_type_option" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="service" :options="get_k9_service_type_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Day" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Day" field="end_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','k9_service') && !check_user_service_operator"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','k9_service') && !check_user_service_operator" >
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_k9_shift(slotProps)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </DataTable>
                    </div>
                    <div v-if="$ability.can('add','k9_service') && !check_user_service_operator" class="row mt-5">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-rounded p-button-text" icon="fa fa-plus" @click="add_k9_service(true)" /> 
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <InputNumber id="k9_quantity" v-model="new_k9_service_shifts.quantity" allowEmpty/>
                                <label for="k9_quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Dropdown id="contact" :options="get_k9_service_type_options" v-model="new_k9_service_shifts.service_type" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex;align-items:center;">
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','k9_service_type_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_k9_service_type_option(slotProps.option.id)"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','k9_service_type_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center my-auto">
                                                    <InputText v-model="new_k9_service_type_option" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_k9_service_type_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Type </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_k9_service_shifts.start_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_k9_service_shifts.start_datetime_hour" @change="new_k9_service_shifts.start_datetime_hour = num2time($event)"/>
                                <label for="contact"> Hour </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_k9_service_shifts.end_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> End </label>
                            </span>
                        </div> 
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_k9_service_shifts.end_datetime_hour" @change="new_k9_service_shifts.end_datetime_hour = num2time($event)"/>
                                <label for="contact"> Hour </label>
                            </span>
                        </div>
                    </div>
                    <div v-if="get_error_k9_services.length > 0" class="col p-error">
                        <div v-for="error in get_error_k9_services" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Final" :disabled="k9_services.status != 'Confirmed'">
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        <DataTable :value="get_k9_services_final" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_k9" @row-edit-save="onRowEditSaveK9">
                            <Column header="Quantity" field="quantity" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Type" field="id_type_option" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="service" :options="get_k9_service_type_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Day" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Day" field="end_datetime" style="width:30rem"> 
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','k9_service')"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','k9_service')" >
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_k9_shift(slotProps)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </DataTable>
                    </div>
                    <div v-if="$ability.can('add','k9_service')" class="row mt-5">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-rounded p-button-text" icon="fa fa-plus" @click="add_k9_service(false)" /> 
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <InputNumber id="k9_quantity" v-model="new_k9_service_shifts.quantity" allowEmpty/>
                                <label for="k9_quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Dropdown id="contact" :options="get_k9_service_type_options" v-model="new_k9_service_shifts.service_type" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex;align-items:center;">
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','k9_service_type_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_k9_service_type_option(slotProps.option.id)"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','k9_service_type_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center my-auto">
                                                    <InputText v-model="new_k9_service_type_option" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_k9_service_type_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Type </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_k9_service_shifts.start_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_k9_service_shifts.start_datetime_hour" @change="new_k9_service_shifts.start_datetime_hour = num2time($event)"/>
                                <label for="contact"> Hour </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_k9_service_shifts.end_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> End </label>
                            </span>
                        </div> 
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" :timeOnly="true" hourFormat="24" v-model="new_k9_service_shifts.end_datetime_hour" @change="new_k9_service_shifts.end_datetime_hour = num2time($event)"/>
                                <label for="contact"> Hour </label>
                            </span>
                        </div>
                    </div>
                    <div v-if="get_error_k9_services.length > 0" class="col p-error">
                        <div v-for="error in get_error_k9_services" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
            <div class="row">
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5">
                        <div class="inline-flex align-items-center">
                            <b> Mail Note </b>
                            <i class="fa fa-circle-info pl-2" v-tooltip="'Please remember that this section is meant for adding a note to the email content, it\'s not the main body of the email.'" type="text" placeholder="Right" style="cursor:pointer;"></i>
                        </div>
                    </Divider>
                    <div class="row pt-3">
                        <div class="col">
                            <TextArea placeholder="This text will be attached to the email sent." rows="5" v-model="k9_services.mail_text" :disabled="!$ability.can('change','k9') || check_user_service_operator"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5">
                        <div class="inline-flex align-items-center">
                            <b> Additional Notes </b>
                        </div>
                    </Divider>
                    <div class="row pt-3">
                        <div class="col">
                            <TextArea rows="5" v-model="k9_services.note_info" :disabled="!$ability.can('change','k9') || check_user_service_operator"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="disabled_buttons == false">
                <div v-if="k9_services.status === 'Modified' || k9_services.status === 'Refused' || k9_services.status === 'Confirmed' || k9_services.status === 'On hold'" class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','k9') && k9_services.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_k9()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_k9') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_k9_mail()"/>
                    </div>
                    <span v-if="k9_services.status && $ability.can('change','k9') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_k9_service()"/>
                    </span>
                </div>
                <div v-else class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','k9') && k9_services.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_k9()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_k9') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_k9_mail()"/>
                    </div>
                    <div v-if="$ability.can('change','k9') || $ability.can('change','k9_service') || $ability.can('add','k9') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <span v-if="k9_services.status && $ability.can('change','k9') && !check_user_service_operator">
                            <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_k9_service()"/>
                        </span>
                        <span v-else-if="!k9_services.status && $ability.can('add','k9') && !check_user_service_operator">
                            <Button label="Create" icon="fa-solid fa-plus" class="p-button-raised p-button-text p-button-success" @click="save_changes_k9_service()"/>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="mail_k9_error">
                <div v-if="mail_k9_error.length > 0" class="row pt-3 text-center">
                    <div class="col" style="color: red;">
                        {{mail_k9_error}}
                    </div>
                </div>
            </div>
        </div>
    </Panel>

    <Dialog v-model:visible="delete_service" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span> Are you sure you want to delete this service? </span>
        </div>
        <template #footer>
            <Button label="No"  class="p-button-text" @click="delete_service = false"/>
            <Button label="Yes" @click="handle_function_delete(service_selected)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>

</template>

<script>
import Panel from 'primevue/panel'
import Button from 'primevue/button'
import TextArea from 'primevue/textarea'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Message from 'primevue/message'
import InputText from 'primevue/inputtext'

import ModalSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/ModalSupplier_responsibles.vue/'

import { num2time, num2timeedit } from '@/modules/utilities.js'

import ability from '../../../services/ability'

export default {
    name: 'K9',
    components: {
        Panel,
        Button,
        TextArea,
        Divider,
        Calendar,
        InputNumber,
        Dropdown,
        Dialog,
        TabPanel,
        TabView,
        DataTable,
        Column,
        Message,
        InputText,
        ModalSupplierResponsibles
    },
    props: {
        service_sheet: {
            type: Object
        },
        all_suppliers: {
            type: Object
        },
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            k9_services: {'id_supplier_responsible': null, 'note_info':'', 'mail_text':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))},
            new_k9_service_shifts: {'k9':'', 'service_type': '', 'quantity': null, 'start_datetime':'', 'start_datetime_hour': '', 'end_datetime': '', 'end_datetime_hour': ''},
            k9_services_shift: [],
            k9_services_shift_estimated: [],
            k9_services_shift_final: [],
            selected_k9_services: [],
            mail_k9_error: null,
            k9_service_responsibles_list: [],
            delete_service: false,
            k9_collapsed: true,
            modal_confirmation_mail: false,
            editing_rows_k9: [],
            new_k9_service_type_option: [],
            created_automatically:false,
            disabled_buttons: false
        }
    },
    computed: {
        get_k9: function() {
            return this.$store.getters.get_k9
        },
        get_k9_services: function() {
            return this.$store.getters.get_k9_services
        },
        get_send_k9_mail: function() {
            return this.$store.getters.get_send_k9_mail
        },
        get_error_k9_services: function() {
            return this.$store.getters.get_error_k9_services
        },
        get_k9_service_type_options: function() {
            return this.$store.getters.get_k9_service_type_options
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_k9_services_estimated: function() {
            return this.$store.getters.get_k9_services_estimated
        },
        get_k9_services_final: function() {
            return this.$store.getters.get_k9_services_final
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        get_error_k9_service_type_options: function() {
            return this.$store.getters.error_k9_service_type_options
        },
        get_min_date: function() {
            return this.$store.getters.min_date
        },
        get_max_date: function() {
            return this.$store.getters.max_date
        }
    },
    async mounted() {
        if(this.get_k9.length > 0) {
            if(ability.can('view','k9_service')) {
                await this.$store.dispatch('load_k9_service', (Number(this.$route.params.sid)))
                this.k9_services = this.get_k9[0]

                this.k9_services_shift_estimated = this.get_k9_services_estimated
                if(this.k9_services_shift_estimated.length > 0) {
                    for(var x in this.k9_services_shift_estimated) {
                        this.k9_services_shift_estimated[x].start_datetime_hour = this.k9_services_shift_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_estimated[x].start_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_estimated[x].start_datetime = this.k9_services_shift_estimated[x].start_datetime.split('T')[0]
                        this.k9_services_shift_estimated[x].end_datetime_hour = this.k9_services_shift_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_estimated[x].end_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_estimated[x].end_datetime = this.k9_services_shift_estimated[x].end_datetime.split('T')[0]
                    }
                }

                this.k9_services_shift_final = this.get_k9_services_final
                if(this.k9_services_shift_final.length > 0) {
                    for(var i in this.k9_services_shift_final) {
                        this.k9_services_shift_final[i].start_datetime_hour = this.k9_services_shift_final[i].start_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_final[i].start_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_final[i].start_datetime = this.k9_services_shift_final[i].start_datetime.split('T')[0]
                        this.k9_services_shift_final[i].end_datetime_hour = this.k9_services_shift_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_final[i].end_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_final[i].end_datetime = this.k9_services_shift_final[i].end_datetime.split('T')[0]
                    }
                }
            }
        } else {
            await this.$store.dispatch('empty_k9_shifts')
        }

        this.mail_k9_error = ''
        this.new_k9_service_shifts = {'k9':'', 'service_type': '', 'quantity': null, 'start_datetime': this.service_sheet.estimated_arrival_datetime.split('T')[0], 'start_datetime_hour': '', 'end_datetime': this.service_sheet.estimated_arrival_datetime.split('T')[0], 'end_datetime_hour': ''}

    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        handleTabChange(event) {
            const selectedTabIndex = event.index // El índice de la pestaña seleccionada
            if (selectedTabIndex === 1) { // Si es la segunda pestaña (índice 1)
                this.disabled_buttons = true
            } else {
                this.disabled_buttons = false
            }
        },
        async fill_shifts(){
            await this.$store.dispatch('load_k9_service', (Number(this.$route.params.sid)))
            this.k9_services = this.get_k9[0]

            this.k9_services_shift_estimated = this.get_k9_services_estimated
            if(this.k9_services_shift_estimated.length > 0) {
                for(var x in this.k9_services_shift_estimated) {
                    this.k9_services_shift_estimated[x].start_datetime_hour = this.k9_services_shift_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_estimated[x].start_datetime.split('T')[1].split(':')[1]
                    this.k9_services_shift_estimated[x].start_datetime = this.k9_services_shift_estimated[x].start_datetime.split('T')[0]
                    this.k9_services_shift_estimated[x].end_datetime_hour = this.k9_services_shift_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_estimated[x].end_datetime.split('T')[1].split(':')[1]
                    this.k9_services_shift_estimated[x].end_datetime = this.k9_services_shift_estimated[x].end_datetime.split('T')[0]
                }
            }

            this.k9_services_shift_final = this.get_k9_services_final
            if(this.k9_services_shift_final.length > 0) {
                for(var i in this.k9_services_shift_final) {
                    this.k9_services_shift_final[i].start_datetime_hour = this.k9_services_shift_final[i].start_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_final[i].start_datetime.split('T')[1].split(':')[1]
                    this.k9_services_shift_final[i].start_datetime = this.k9_services_shift_final[i].start_datetime.split('T')[0]
                    this.k9_services_shift_final[i].end_datetime_hour = this.k9_services_shift_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.k9_services_shift_final[i].end_datetime.split('T')[1].split(':')[1]
                    this.k9_services_shift_final[i].end_datetime = this.k9_services_shift_final[i].end_datetime.split('T')[0]
                }
            }
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        handle_function_delete(function_name){
            this[function_name]()
            this.delete_service = false
            this.service_selected = ''
        },
        async add_k9_service(data) {
            if(this.get_k9.length == 0){
                await this.$store.dispatch('create_k9_service', this.k9_services)
                await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
                this.k9_services = this.get_k9[0]
            }

            this.new_k9_service_shifts.is_estimated = data
            this.new_k9_service_shifts.k9 = this.get_k9[0].id

            await this.$store.dispatch('create_k9_services', this.new_k9_service_shifts)
            this.new_k9_service_shifts = {'k9':'', 'service_type': '', 'quantity': null, 'start_datetime':'', 'end_datetime': '',}
            /*
            await this.$store.dispatch('load_k9_service', (Number(this.$route.params.sid)))
            this.k9_services_shift_estimated = this.get_k9_services_estimated
            this.k9_services_shift_final = this.get_k9_services_final

            if(this.k9_services_shift_estimated) {
                for(var x in this.k9_services_shift_estimated) {
                    if(this.k9_services_shift_estimated[x].start_datetime) {
                        this.k9_services_shift_estimated[x].start_datetime_hour = this.k9_services_shift_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' +  this.k9_services_shift_estimated[x].start_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_estimated[x].start_datetime =  this.k9_services_shift_estimated[x].start_datetime.split('T')[0] 
                        this.k9_services_shift_estimated[x].end_datetime_hour = this.k9_services_shift_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' +  this.k9_services_shift_estimated[x].end_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_estimated[x].end_datetime =  this.k9_services_shift_estimated[x].end_datetime.split('T')[0] 
                    }   
                }
            }

            if(this.k9_services_shift_final) {
                for(var i in this.k9_services_shift_estimated) {
                    if(this.k9_services_shift_estimated[i].start_datetime) {
                        this.k9_services_shift_estimated[i].start_datetime_hour = this.k9_services_shift_estimated[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.k9_services_shift_estimated[i].start_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_estimated[i].start_datetime =  this.k9_services_shift_estimated[i].start_datetime.split('T')[0] 
                        this.k9_services_shift_estimated[i].end_datetime_hour = this.k9_services_shift_estimated[i].end_datetime.split('T')[1].split(':')[0] + ':' +  this.k9_services_shift_estimated[i].end_datetime.split('T')[1].split(':')[1]
                        this.k9_services_shift_estimated[i].end_datetime =  this.k9_services_shift_estimated[i].end_datetime.split('T')[0] 
                    }   
                }
            }
            */
            await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
            this.k9_services = this.get_k9[0]

            this.loading = false
            
        },
        async add_k9_service_type_option() {
            await this.$store.dispatch('create_k9_service_type_option', this.new_k9_service_type_option)
            if(this.get_error_k9_service_type_options.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Option created successfully', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_k9_service_type_options.name[0], life: 3000});
            }
            this.new_k9_service_type_option = ''
        },
        async fill_k9_service(e) {
            if (e!= null){
                if(e.value === false || this.k9_collapsed === false){
                    if (this.k9_services.status === undefined){
                        await this.$store.dispatch('create_past_k9', (Number(this.$route.params.sid)))
                        if(this.get_k9[0] !== undefined){
                            this.k9_services  = this.get_k9[0]
                            this.fill_shifts()
                            this.created_automatically=true
                        }
                    }
                }
            }
            await this.$store.dispatch('load_port_supplier_service')

            for(var item in this.get_supplier_service) {
                if(this.get_supplier_service[item].service == 'K9') {
                    this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[item].id_supplier.id)
                }
            }

            this.terminals_security_service_responsibles_list = this.get_supplier_service_responsible
            
            if(this.get_k9.length == 0) {
                for(var index in this.get_supplier_service){
                    if(this.get_supplier_service[index].service == 'K9') {
                        this.k9_services.id_supplier = this.get_supplier_service[index].id_supplier
                        this.k9_services.id_supplier_responsible = this.get_supplier_service[index].id_supplier_responsible
                    }
                }
            }

            this.load_k9_service_responsibles()

        },
        async onRowEditSaveK9(event) {

            await this.$store.dispatch('clean_errors_k9_shifts')
            let { newData } = event;
            await this.$store.dispatch('update_k9_services', newData)
            await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
            this.k9_services = this.get_k9[0]

            if(this.get_error_k9_services.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Porters service updated successfully', life: 3000});
            }

        },
        async load_k9_service_responsibles() {
            await this.$store.dispatch('load_supplier_service_responsible', this.k9_services.id_supplier.id)
            this.k9_service_responsibles_list = this.get_supplier_service_responsible

            if(this.k9_service_responsibles_list.length != 0) {
                this.k9_services.id_supplier_responsible = this.k9_service_responsibles_list[0]
            } else {
                this.k9_services.id_supplier_responsible = ''
            }
    
        },
        change_k9_service() {
            this.mail_k9_error = null
        }, 
        async save_changes_k9_service() {
            this.mail_k9_error = ''

            if(this.get_k9.length == 0) {
                await this.$store.dispatch('create_k9_service', this.k9_services)
                await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
                this.k9_services = this.get_k9[0]

                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'K9 service created successfully', life: 3000});
            } else {
                await this.$store.dispatch('update_k9', this.k9_services)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'K9 service updated successfully', life: 3000});
            }

            if(this.get_k9.length != 0) {
                await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
                this.k9_services = this.get_k9[0]
            }
            
        },
        async delete_k9_shift(event) {

            await this.$store.dispatch('delete_k9_service', event.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shift deleted', life: 3000});
            await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
            this.k9_services = this.get_k9[0]

        },
        async dialog_confirmation_k9_mail() {
            this.modal_confirmation_mail = true
            this.mail_selected = 'send_k9_service_mail'
        },
        async send_k9_service_mail() {
            this.mail_k9_error = ''
            
            if(this.mail_k9_error == '' && this.get_error_k9_services.length == 0) {
                if(this.get_k9_services_estimated.length > 0) {
                    if(this.k9_services.status == 'None' || this.k9_services.status == 'Modified' || this.k9_services.status == 'Refused') {
                        await this.$store.dispatch('send_k9_service_mail', (Number(this.$route.params.sid)), this.k9_services)
                        this.mail_k9_error = this.get_error_k9_services
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                    } else {
                        if(this.get_send_k9_mail == false) {
                            this.mail_k9_error = 'Cannot send email with the same data as the previous.' 
                        } else {
                            await this.$store.dispatch('send_k9_service_mail', (Number(this.$route.params.sid)), this.k9_services)
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                        }
                    }
                } else {
                    this.mail_k9_error = 'Cannot send mail without any shift. Please make sure to add at least one.'
                }

                if(this.get_k9.length != 0) {
                    await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
                    this.k9_services = this.get_k9[0]
                }
                
            }
           
        },
        async delete_k9() {
            this.service_selected = 'delete_k9_confirmation'
            this.delete_service = true
        },
        async delete_k9_service_type_option(id) {
            await this.$store.dispatch('delete_k9_service_type_option', id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Option removed', life: 3000});
        },
        async delete_k9_confirmation() {

            this.mail_k9_error = ''
            
            await this.$store.dispatch('delete_k9', this.k9_services.id)
            await this.$store.dispatch('load_k9', (Number(this.$route.params.sid)))
            
            this.k9_services = {'id_supplier_responsible': null, 'note_info':'', 'mail_text':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))}
            this.k9_services_shift = []
            
            if(this.get_error_k9_services.detail === 'Not found.') {
                this.mail_k9_error = 'Cannot delete empty service.'
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Service deleted', life: 3000})
            }
            await this.$store.dispatch('empty_k9_shifts')
            this.fill_k9_service()
        },
        async k9_change_supplier() {
            await this.$store.dispatch('update_k9', this.k9_services)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Service updated', life: 3000})
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
    }
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
    border-radius: 0%;
}

.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}


:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>