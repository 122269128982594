<template>
    <Panel header="Cleaning Service" v-model:collapsed="cleaning_collapsed" :collapsed="true" :toggleable="true" @toggle="fill_cleaning_service($event)">
        <template #header>
            <div @click="cleaning_collapsed = !cleaning_collapsed; fill_cleaning_service($event)" class="col" style="padding: 0.7rem;" >
                <div class="row">
                    <div class="col">
                        <span class="title_panels"> Cleaning </span>
                    </div>
                </div>
            </div>
        </template>
        <template #icons>
            <i v-if="created_automatically" v-tooltip.left="'This service was created automatically with previous data.'" class="fa-solid fa-circle-info fa-xl" style="color: #fcc203;"></i>    
            <i v-if="cleaning_service.status" class="p-panel-header-icon mr-2">
                <span v-if="cleaning_service.status === 'None'">                    
                    <i v-tooltip.left="'Service email not sent.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: #002d7d;"></i>
                </span>
                <span v-if="cleaning_service.status === 'Modified'">
                    <i v-tooltip.left="'Service modified after email.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: orange;"></i>
                </span>
                <span v-if="cleaning_service.status === 'On hold'">
                    <i v-tooltip.left="'Service email sent and waiting for confirmation.'" class="fa-solid fa-circle fa-xl" style="color: orange;"></i>
                </span>
                <span v-if="cleaning_service.status === 'Confirmed'">
                    <i v-tooltip.left="'Service email confirmed.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></i>
                </span>
                <span v-if="cleaning_service.status === 'Refused'">
                    <i v-tooltip.left="'Service email refused.'" class="fa-solid fa-circle fa-xl" style="color: #EF4444;"></i>
                </span>
            </i>
        </template>
        <div class="container-fluid p-fluid">
            <div class="row">
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Supplier </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="all_suppliers" :disabled="!$ability.can('change','cleaning') || check_user_service_operator" :filter="true" optionLabel="name" v-model="cleaning_service.id_supplier"  @change="load_cleaning_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Responsible </b>                                
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="cleaning_service_responsibles_list" :disabled="!$ability.can('change','cleaning') || check_user_service_operator" placeholder="Select a responsible..." :filter="true" optionLabel="name" v-model="cleaning_service.id_supplier_responsible" @click="load_cleaning_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                
            </div>
            <div v-if="$ability.can('add','supplier_responsible') || !check_user_service_operator" class="row justify-content-end">
                <div class="col-12 sm:col-4 md:col-3 text-end">
                    <ModalSupplierResponsibles :service_sheet="true"/>
                </div>
            </div>
            <Divider align="center" class="mt-2 mb-4">
                <div class="inline-flex align-items-center">
                    <b> Shifts </b>
                </div>
            </Divider>
            <TabView @tab-click="handleTabChange">
                <TabPanel header="Estimation">
                    <div v-if="cleaning_service.status == 'Pending'">
                        <Message :closable="false" severity="info"> The email of this service has already been sent, if any modifications are made, the previous email will be discarded. </Message>
                    </div>
                    <div v-if="cleaning_service.status == 'Confirmed'">
                        <Message :closable="false" severity="info"> This service has already been confirmed. If any modifications are made, the status will change, and the previous email will be discarded. </Message>
                    </div>
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        <DataTable :value="get_cleaning_service_shifts_estimated" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_cleaning" @row-edit-save="onRowEditSaveCleaning">
                            <Column header="Quantity" field="quantity" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Day" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Day" field="end_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','cleaning_service') && !check_user_service_operator"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','cleaning_service') && !check_user_service_operator" >
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_cleaning_shift(slotProps)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </DataTable>
                    </div>
                    <div v-if="$ability.can('add','cleaning_service') && !check_user_service_operator" class="row mt-5">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-text p-button-rounded" icon="fa fa-plus" @click="add_cleaning_shift(true)" style="height: 2.5rem; width: 2.5rem;"/> 
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <InputNumber v-model="new_cleaning_service_shift.quantity"/>
                                <label for="quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.start_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day</label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.start_datetime_hour" :timeOnly="true"  hourFormat="24" dateFormat="yy-mm-dd" @change="new_cleaning_service_shift.start_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.end_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.end_datetime_hour" :timeOnly="true"  hourFormat="24" dateFormat="yy-mm-dd" @change="new_cleaning_service_shift.end_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> End  </label>
                            </span>
                        </div>
                    </div>
                    <div v-if="get_error_cleaning_service_shifts.length > 0" class="col p-error">
                        <div v-for="error in get_error_cleaning_service_shifts" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>  
                <TabPanel header="Final" :disabled="cleaning_service.status != 'Confirmed'">
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        <DataTable :value="get_cleaning_service_shifts_final" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_cleaning" @row-edit-save="onRowEditSaveCleaning">
                            <Column header="Quantity" field="quantity" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Day" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Day" field="end_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime_hour" style="width:30rem">
                                <template #body="{ data, field }">
                                    <span> {{data[field]}} </span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','cleaning_service')"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','cleaning_service')">
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_cleaning_shift(slotProps)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </DataTable>
                    </div>
                    <div v-if="$ability.can('add','cleaning_service')" class="row mt-5">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-text p-button-rounded" icon="fa fa-plus" @click="add_cleaning_shift(false)" style="height: 2.5rem; width: 2.5rem;"/> 
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <InputNumber v-model="new_cleaning_service_shift.quantity"/>
                                <label for="quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.start_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day</label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.start_datetime_hour" :timeOnly="true"  hourFormat="24" dateFormat="yy-mm-dd" @change="new_cleaning_service_shift.start_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.end_datetime" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_cleaning_service_shift.end_datetime_hour" :timeOnly="true"  hourFormat="24" dateFormat="yy-mm-dd" @change="new_cleaning_service_shift.end_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> End  </label>
                            </span>
                        </div>
                    </div>
                    <div v-if="get_error_cleaning_service_shifts.length > 0" class="col p-error" >
                        <div v-for="error in get_error_cleaning_service_shifts" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>    
            </TabView>
            <div class="row">
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5 mb-3">
                        <div class="inline-flex align-items-center">
                            <b> Mail Note </b>
                            <i class="fa fa-circle-info pl-2" v-tooltip="'Please remember that this section is meant for adding a note to the email content, it\'s not the main body of the email.'" type="text" placeholder="Right" style="cursor:pointer;"></i>
                        </div>
                    </Divider>
                    <div class="row pt-2 pb-2">
                        <div class="col">
                            <TextArea placeholder="This text will be attached to the email sent." rows="5" v-model="cleaning_service.mail_text" :disabled="!$ability.can('change','cleaning') || check_user_service_operator"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5 mb-3">
                        <div class="inline-flex align-items-center">
                            <b> Additional Notes </b>
                        </div>
                    </Divider>
                    <div class="row pt-2 pb-2">
                        <div class="col">
                            <TextArea rows="5" v-model="cleaning_service.note_info" :disabled="!$ability.can('change','cleaning') || check_user_service_operator"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="disabled_buttons == false">                         
                <div v-if="cleaning_service.status === 'Modified' || cleaning_service.status === 'Refused' || cleaning_service.status === 'Confirmed' || cleaning_service.status === 'On hold'" class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','cleaning') && cleaning_service.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_cleaning_service()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_cleaning') && !check_user_service_operator " class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_cleaning_mail()"/>
                    </div>
                    <span v-if="cleaning_service.status && $ability.can('change','cleaning') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_cleaning_service()"/>
                    </span>
                </div>
                <div v-else class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','cleaning') && cleaning_service.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_cleaning_service()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_cleaning') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_cleaning_mail()"/>
                    </div>
                    <div v-if="$ability.can('change','cleaning') || $ability.can('change','cleaning_service') || $ability.can('add','cleaning') " class="col-12 md:col-3 lg:col-2">
                        <span v-if="cleaning_service.status && $ability.can('change','cleaning') && !check_user_service_operator">
                            <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_cleaning_service()"/>
                        </span>
                        <span v-else-if="!cleaning_service.status && $ability.can('add','cleaning') && !check_user_service_operator">
                            <Button label="Create" icon="fa-solid fa-plus" class="p-button-raised p-button-text p-button-success" @click="save_changes_cleaning_service()"/>
                        </span>
                    </div>
                </div>
            </div>    
            <span v-if="mail_cleaning_error">
                <div v-if="mail_cleaning_error.length > 0" class="row pt-3 text-center">
                    <div class="col" style="color: red;">
                        {{mail_cleaning_error}}
                    </div>
                </div>
            </span>
        </div>
    </Panel>

    <Dialog v-model:visible="delete_service" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span> Are you sure you want to delete this service? </span>
        </div>
        <template #footer>
            <Button label="No"  class="p-button-text" @click="delete_service = false"/>
            <Button label="Yes" @click="handle_function_delete(service_selected)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>

</template>

<script>
import Panel from 'primevue/panel'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import TextArea from 'primevue/textarea'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Message from 'primevue/message';

import ModalSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/ModalSupplier_responsibles.vue/'

import { num2time, num2timeedit } from '@/modules/utilities.js'

import moment from 'moment'
// import ability from '../../../services/ability'

export default {
    name: 'Cleaning',
    components: {
        Panel,
        Button,
        Divider,
        TextArea,
        Calendar,
        InputNumber,
        Dropdown,
        Dialog,
        TabPanel,
        TabView,
        DataTable,
        Column,
        Message,
        ModalSupplierResponsibles
    },
    props: {
        service_sheet: {
            type: Object
        },
        days: {
            type: Number
        },
        all_suppliers: {
            type: Object
        },
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            cleaning_service: {'id_supplier_responsible': null, 'note_info':'', 'mail_text':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))},
            new_cleaning_service_shift: {'cleaning':'', 'quantity': null, 'start_datetime': '', 'start_datetime_hour': '', 'end_datetime': '', 'end_datetime_hour': '',},
            cleaning_service_shifts_estimated: [],
            cleaning_service_shifts_final: [],
            selected_cleaning_shifts: [], // Schedules from cleaning services selected with the checkbox
            mail_cleaning_error: null,
            cleaning_service_responsibles_list: [],
            delete_service: false,
            cleaning_collapsed: true,
            modal_confirmation_mail: false,
            editing_rows_cleaning: [],
            created_automatically:false,
            disabled_buttons: false
        }
    },
    computed: {
        get_cleaning_service: function() {
            return this.$store.getters.get_cleaning_service
        },
        get_past_cleaning: function() {
            return this.$store.getters.get_past_cleaning
        },
        get_cleaning_service_shifts: function() {
            return this.$store.getters.get_cleaning_service_shifts
        },
        get_cleaning_service_shifts_estimated: function() {
            return this.$store.getters.get_cleaning_service_shifts_estimated
        },
        get_cleaning_service_shifts_final: function() {
            return this.$store.getters.get_cleaning_service_shifts_final
        },
        get_send_cleaning_mail: function() {
            return this.$store.getters.get_send_cleaning_mail
        },
        get_error_cleaning_service_shifts: function() {
            return this.$store.getters.get_error_cleaning_service_shifts
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        get_min_date: function() {
            return this.$store.getters.min_date
        },
        get_max_date: function() {
            return this.$store.getters.max_date
        }
    },
    async mounted(){
        // Cleaning Service check if exists
        if(this.get_cleaning_service.length > 0) {
            await this.$store.dispatch('load_cleaning_service_shifts', (Number(this.$route.params.sid)))
            this.cleaning_service = this.get_cleaning_service[0]
            
            this.cleaning_service_shifts_estimated = this.get_cleaning_service_shifts_estimated
            if(this.cleaning_service_shifts_estimated) {
                for(var x in this.cleaning_service_shifts_estimated) {
                    this.cleaning_service_shifts_estimated[x].start_datetime_hour =  this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' + this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_estimated[x].start_datetime =  this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[0]
                    this.cleaning_service_shifts_estimated[x].end_datetime_hour = this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_estimated[x].end_datetime =  this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[0]
                }
            }

            this.cleaning_service_shifts_final = this.get_cleaning_service_shifts_final
            if(this.cleaning_service_shifts_final) {
                for(var i in this.cleaning_service_shifts_final) {
                    this.cleaning_service_shifts_final[i].start_datetime_hour =  this.cleaning_service_shifts_final[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.cleaning_service_shifts_final[i].start_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_final[i].start_datetime =  this.cleaning_service_shifts_final[i].start_datetime.split('T')[0]
                    this.cleaning_service_shifts_final[i].end_datetime_hour = this.cleaning_service_shifts_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.cleaning_service_shifts_final[i].end_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_final[i].end_datetime =  this.cleaning_service_shifts_final[i].end_datetime.split('T')[0]
                }
            }
        } else {
            await this.$store.dispatch('empty_cleaning_shifts')
        }

        this.mail_cleaning_error = ''
        this.new_cleaning_service_shift =  {'cleaning':'', 'quantity': null, 'start_datatime': '', 'start_datatime_hour': '', 'end_datatime': '', 'end_datatime_hour': '',}

    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        handleTabChange(event) {
            const selectedTabIndex = event.index // El índice de la pestaña seleccionada
            if (selectedTabIndex === 1) { // Si es la segunda pestaña (índice 1)
                this.disabled_buttons = true
            } else {
                this.disabled_buttons = false
            }
        },
        async fill_shifts(){
            await this.$store.dispatch('load_cleaning_service_shifts', (Number(this.$route.params.sid)))
            this.cleaning_service = this.get_cleaning_service[0]
            
            this.cleaning_service_shifts_estimated = this.get_cleaning_service_shifts_estimated
            if(this.cleaning_service_shifts_estimated) {
                for(var x in this.cleaning_service_shifts_estimated) {
                    this.cleaning_service_shifts_estimated[x].start_datetime_hour =  this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' + this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_estimated[x].start_datetime =  this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[0]
                    this.cleaning_service_shifts_estimated[x].end_datetime_hour = this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_estimated[x].end_datetime =  this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[0]
                }
            }

            this.cleaning_service_shifts_final = this.get_cleaning_service_shifts_final
            if(this.cleaning_service_shifts_final) {
                for(var i in this.cleaning_service_shifts_final) {
                    this.cleaning_service_shifts_final[i].start_datetime_hour =  this.cleaning_service_shifts_final[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.cleaning_service_shifts_final[i].start_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_final[i].start_datetime =  this.cleaning_service_shifts_final[i].start_datetime.split('T')[0]
                    this.cleaning_service_shifts_final[i].end_datetime_hour = this.cleaning_service_shifts_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.cleaning_service_shifts_final[i].end_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_final[i].end_datetime =  this.cleaning_service_shifts_final[i].end_datetime.split('T')[0]
                }
            }
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        format_hour(value){
            return moment(value).format('HH:mm')
        },
        // Function that adds new empty schedule to the cleaning service so the user can fill
        async add_cleaning_shift(data){
            this.mail_cleaning_error = ''

            // Checks if there is no cleaning service created first
            if(this.get_cleaning_service.length == 0){
                await this.$store.dispatch('create_cleaning_service', this.cleaning_service)
                await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
                this.cleaning_service = this.get_cleaning_service[0]
            }

            this.new_cleaning_service_shift.is_estimated = data
            this.new_cleaning_service_shift.cleaning = this.get_cleaning_service[0].id

            await this.$store.dispatch('create_cleaning_service_shifts', this.new_cleaning_service_shift)
            this.new_cleaning_service_shift = {'cleaning':'', 'quantity': null, 'day':'', 'start_datetime': '', 'end_datetime': ''}
            
            await this.$store.dispatch('load_cleaning_service_shifts', (Number(this.$route.params.sid)))
            this.cleaning_service_shifts_estimated = this.get_cleaning_service_shifts_estimated
            this.cleaning_service_shifts_final = this.get_cleaning_service_shifts_final
         
                
            if(this.cleaning_service_shifts_estimated) {
                for(var x in this.cleaning_service_shifts_estimated) {
                    this.cleaning_service_shifts_estimated[x].start_datetime_hour =  this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' +  this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_estimated[x].start_datetime =  this.cleaning_service_shifts_estimated[x].start_datetime.split('T')[0]
                    this.cleaning_service_shifts_estimated[x].end_datetime_hour =  this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' +  this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_estimated[x].end_datetime =  this.cleaning_service_shifts_estimated[x].end_datetime.split('T')[0]
                    
                }
            }
            
            if(this.cleaning_service_shifts_final) {
                for(var i in this.cleaning_service_shifts_final) {
                    this.cleaning_service_shifts_final[i].start_datetime_hour =  this.cleaning_service_shifts_final[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.cleaning_service_shifts_final[i].start_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_final[i].start_datetime =  this.cleaning_service_shifts_final[i].start_datetime.split('T')[0]
                    this.cleaning_service_shifts_final[i].end_datetime_hour = this.cleaning_service_shifts_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.cleaning_service_shifts_final[i].end_datetime.split('T')[1].split(':')[1]
                    this.cleaning_service_shifts_final[i].end_datetime =  this.cleaning_service_shifts_final[i].end_datetime.split('T')[0]
                }
            }
           


            await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
            this.cleaning_service = this.get_cleaning_service[0]

            this.loading = false
        },
        async fill_cleaning_service(e = null) {
            if(e!=null){

                if(e.value === false || this.cleaning_collapsed === false){
                    
                    if (this.cleaning_service.status === undefined){
                        await this.$store.dispatch('create_past_cleaning', (Number(this.$route.params.sid)))
                        if (this.get_cleaning_service[0] !== undefined){
                            this.cleaning_service  = this.get_cleaning_service[0]
                            this.fill_shifts()
                            this.created_automatically = true
                        }
                        
                    }
                }
            }
                await this.$store.dispatch('load_port_supplier_service')
            for(var item in this.get_supplier_service) {
                if(this.get_supplier_service[item].service == 'Cleaning') {
                    this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[item].id_supplier.id)
                }
            }
            
            this.cleaning_service_responsibles_list = this.get_supplier_service_responsible
            if(this.get_cleaning_service.length == 0) {
                for(var index in this.get_supplier_service){
                    if(this.get_supplier_service[index].service == 'Cleaning') {
                        this.cleaning_service.id_supplier = this.get_supplier_service[index].id_supplier
                        this.cleaning_service.id_supplier_responsible = this.get_supplier_service[index].id_supplier_responsible
                    }
                }
            }
            this.load_cleaning_responsibles()

        },
        async onRowEditSaveCleaning(event) {
            this.mail_cleaning_error = ''
            await this.$store.dispatch('clean_errors_cleaning_shifts')
            let { newData } = event;
            await this.$store.dispatch('update_cleaning_service_shifts', newData)
            await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
            this.cleaning_service = this.get_cleaning_service[0]

            if(this.get_error_cleaning_service_shifts.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Porters service updated successfully', life: 3000});
            }

        },
        async load_cleaning_responsibles() {
            await this.$store.dispatch('load_supplier_service_responsible', this.cleaning_service.id_supplier.id)
            this.cleaning_service_responsibles_list = this.get_supplier_service_responsible

            if(this.cleaning_service_responsibles_list.length != 0) {
                this.cleaning_service.id_supplier_responsible = this.cleaning_service_responsibles_list[0]
            } else {
                this.cleaning_service.id_supplier_responsible = ''
            }
        },
        async save_changes_cleaning_service(){
            this.mail_cleaning_error = ''
            if(this.get_cleaning_service.length == 0) {
                await this.$store.dispatch('create_cleaning_service', this.cleaning_service)
                await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
                this.cleaning_service = this.get_cleaning_service[0]

                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Cleaning service created successfully', life: 3000});
            } else {
                await this.$store.dispatch('update_cleaning_service', this.cleaning_service)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Cleaning service updated successfully', life: 3000});
            }
            if(this.get_cleaning_service.length != 0){
                await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
                this.cleaning_service = this.get_cleaning_service[0]
            }

        },
        async delete_cleaning_shift(event){

            await this.$store.dispatch('delete_cleaning_service', event.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shift/s deleted', life: 3000});
            await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
            this.cleaning_service = this.get_cleaning_service[0]

        },
        async dialog_confirmation_cleaning_mail() {

            this.modal_confirmation_mail = true
            this.mail_selected = 'send_cleaning_service_mail'

        },
        async send_cleaning_service_mail() { 
            this.mail_cleaning_error = ''
            
            await this.$store.dispatch('update_cleaning_service', this.cleaning_service)

            if(this.mail_cleaning_error == '') {
                if(this.get_cleaning_service_shifts_estimated.length > 0) {
                    if(this.cleaning_service.status == 'None' || this.cleaning_service.status == 'Modified' || this.cleaning_service.status == 'Refused') {
                        await this.$store.dispatch('send_cleaning_service_mail', (Number(this.$route.params.sid)), this.cleaning_service)
                        this.mail_cleaning_error = this.get_error_cleaning_service_shifts
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                    } else {
                        if(this.get_send_cleaning_mail == false) {
                            this.mail_cleaning_error = 'Cannot send email with the same data as the previous.' 
                        } else {
                            await this.$store.dispatch('send_cleaning_service_mail', (Number(this.$route.params.sid)), this.cleaning_service)
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                        }
                    }
                } else {
                    this.mail_cleaning_error = 'Cannot send mail without any shift. Please make sure to add at least one.'
                }

                if(this.get_cleaning_service.length != 0){
                    await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
                    this.cleaning_service = this.get_cleaning_service[0]
                }
            } 

        },
        async delete_cleaning_service() {
            this.service_selected = 'delete_cleaning_service_confirmation'
            this.delete_service = true
        },
        async delete_cleaning_service_confirmation() {

            this.mail_cleaning_error = ''
            
            await this.$store.dispatch('delete_cleaning', this.cleaning_service.id)
            await this.$store.dispatch('load_cleaning_service', (Number(this.$route.params.sid)))
            
            this.cleaning_service = {'id_supplier_responsible': null, 'note_info':'', 'mail_text':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))}
            this.cleaning_service_shifts = []
            
            if(this.get_error_cleaning_service_shifts.detail === 'Not found.') {
                this.mail_cleaning_error = 'Cannot delete empty service.'
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Service deleted', life: 3000})
            }
            this.fill_cleaning_service()
        },
        async cleaning_change_supplier() {
            await this.$store.dispatch('update_cleaning_service', this.cleaning_service)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Service updated', life: 3000})
        },
        handle_function_delete(function_name){
            this[function_name]()
            this.delete_service = false
            this.service_selected = ''
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
    border-radius: 0%;
}

.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}


:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>