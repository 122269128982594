<template>
    <Panel header="Porters Service" v-model:collapsed="porters_collapsed" :collapsed="true" :toggleable="true" @toggle="fill_porters_service($event)">
        <template #header>
            <div @click="porters_collapsed = !porters_collapsed; fill_porters_service($event)" class="col" style="padding: 0.7rem;">
                <div class="row"  >
                    <div class="col">
                        <span class="title_panels"> Porters </span>
                    </div>
                </div>
            </div>
        </template>
        <template #icons>
            <i v-if="created_automatically" v-tooltip.left="'This service was created automatically with previous data.'" class="fa-solid fa-circle-info fa-xl" style="color: #fcc203;"></i>    
            <i v-if="porters_service" class="p-panel-header-icon mr-2">
                <span v-if="porters_service.status === 'None'">
                    <span v-tooltip.left="'Service email not sent.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: #002d7d;"></span>
                </span>
                <span v-else-if="porters_service.status === 'Modified'">
                    <span v-tooltip.left="'Service modified after email.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: orange;"></span>
                </span>
                <span v-else-if="porters_service.status === 'On hold'">
                    <span v-tooltip.left="'Service email sent and waiting for confirmation.'" class="fa-solid fa-circle fa-xl" style="color: orange;"></span>
                </span>
                <span v-else-if="porters_service.status === 'Confirmed'">
                    <span v-tooltip.left="'Service email confirmed.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></span>
                </span>
                <span v-else-if="porters_service.status === 'Refused'">
                    <span v-tooltip.left="'Service email refused.'" class="fa-solid fa-circle fa-xl" style="color: #EF4444;"></span>
                </span>
            </i>
        </template>
        <div class="container-fluid p-fluid">
            <div class="row">
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Supplier </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="all_suppliers" :disabled="!$ability.can('change','porter') || check_user_service_operator" :filter="true" optionLabel="name" v-model="porters_service.id_supplier" @change="load_porters_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Responsible </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="porters_service_responsibles_list" placeholder="Select a responsible..." :disabled="!$ability.can('change','porter') || check_user_service_operator" :filter="true" optionLabel="name" v-model="porters_service.id_supplier_responsible" @click="load_porters_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$ability.can('add','supplier_responsible')" class="row justify-content-end">
                <div class="col-12 md:col-3">
                    <ModalSupplierResponsibles :service_sheet="true"/>
                </div>
            </div>
            <Divider align="center" class="mt-2 mb-3">
                <div class="inline-flex align-items-center">
                    <b> Default Services </b>
                </div>
            </Divider>

            <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                <DataTable :value="get_porters_shift_services" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_shift_porters" @row-edit-save="onRowEditSavePortersShifts">
                    <Column header="Shift type" field="shift_type"  style="width:30rem">
                        <template #editor="{ data, field }">
                            <Dropdown :options="shift_types" v-model="data[field]" optionLabel="name" optionValue="name"/>
                        </template>
                    </Column>
                    <Column header="Passengers Quantity" field="passengers_quantity" style="width:30rem">
                        <template #editor="{ data, field }">
                            <InputNumber v-model="data[field]" autofocus />
                        </template>
                    </Column>
                    <Column header="Day" field="start_datetime" style="width:30rem">
                        <template #editor="{ data, field }">
                            <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                        </template>
                    </Column>
                    <Column header="Hour" field="start_datetime_hour" style="width:30rem">
                        <template #body="{data,field}">
                            <span> {{data[field]}}</span>
                        </template>
                        <template #editor="{ data, field }">
                            <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                        </template>
                    </Column>
                    <Column header="Day" field="end_datetime" style="width:30rem">
                        <template #editor="{ data, field }">
                            <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                        </template>
                    </Column>
                    <Column header="Hour" field="end_datetime_hour" style="width:30rem">
                        <template #body="{data,field}">
                            <span> {{data[field]}}</span>
                        </template>
                        <template #editor="{ data, field }">
                            <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                        </template>
                    </Column>
                    <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','porter_shift_service') && !check_user_service_operator"></Column>
                    <Column header="Delete" v-if="$ability.can('delete','porter_shift_service') && !check_user_service_operator" >
                        <template #body="slotProps">
                            <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_porters_shift(slotProps)"/>
                        </template>
                    </Column>
                    <template #empty>
                        <div class="row">
                            <div class="col text-center">
                                No records.
                            </div>
                        </div>
                    </template>
                </DataTable>
            </div>
            <div v-if="$ability.can('add','porter_shift_service') && !check_user_service_operator" class="row mt-5">
                <div class="col-12 md:col-1 text-center mt-1">
                    <Button class="p-button-raised p-button-text p-button-rounded" icon="fa fa-plus" @click="add_porters_shift(true)" style="height: 2.5rem; width: 2.5rem;" /> 
                </div>
                <div class="col-12 md:col-2 mt-2">
                    <span class="p-float-label"> 
                        <Dropdown id="shif_types" :options="shift_types" v-model="new_porters_service_shift.shift_type" optionValue="name" optionLabel="name">
                            
                        </Dropdown>
                        <label for="shif_types"> Shift type </label>
                    </span>
                </div>
                <div class="col-12 md:col mt-2">
                    <span class="p-float-label">
                        <InputNumber id="passengers_quantity" v-model="new_porters_service_shift.passengers_quantity"/>
                        <label > Passengers quantity </label>
                    </span>
                </div>
                <div class="col-12 md:col-2 mt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="day_start" v-model="new_porters_service_shift.start_day" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                        <label for="day_start"> Day</label>
                    </span>
                </div>
                <div class="col-12 md:col mt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="hour_start" v-model="new_porters_service_shift.start_shift" :timeOnly="true" @change="new_porters_service_shift.start_shift = num2time($event)"/>
                        <label for="hour_start"> Start </label>
                    </span>
                </div>
                <div class="col-12 md:col-2 mt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="day_end" v-model="new_porters_service_shift.end_day" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                        <label for="day_end"> Day</label>
                    </span>
                </div>
                <div class="col-12 md:col mt-2">
                    <span class="p-float-label">
                        <Calendar autocomplete="off" id="hour_end" v-model="new_porters_service_shift.end_shift" :timeOnly="true" @change="new_porters_service_shift.end_shift = num2time($event)"/>
                        <label for="hour_end"> End  </label>
                    </span>
                </div>
            </div>
            <div v-if="get_error_porters_shift_services.length > 0" class="col p-error" style="color: red;">
                <div v-for="error in get_error_porters_shift_services" :key="error">
                    <ul>    
                        <li v-if="error.shif_type">Shift type: {{error.shif_type[0]}}</li>
                        <li v-if="error.shif_type">Passengers quantity: {{error.passengers_quantity[0]}}</li>
                        <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                        <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                        <li v-if="error">{{error.service_sheet}}</li>
                    </ul>
                </div>
            </div>


            <Divider align="center" class="mt-2 mb-3">
                <div class="inline-flex align-items-center">
                    <b> Extra Services </b>
                </div>
            </Divider>
            <TabView @tab-click="handleTabChange">
                <TabPanel header="Estimation">
                    <div v-if="porters_service.status == 'Pending'">
                        <Message :closable="false" severity="info"> The email of this service has already been sent, if any modifications are made, the previous email will be annulled. </Message>
                    </div>
                    <div v-if="porters_service.status == 'Confirmed'">
                        <Message :closable="false" severity="info"> This service has already been confirmed. If any modifications are made, the status will change, and the previous email will be annulled. </Message>
                    </div>
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        
                        <DataTable :value="get_porters_service_extra_services_estimated" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_porters" @row-edit-save="onRowEditSavePorters">
                            <Column header="Quantity" field="quantity"  style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Service" field="id_service_option"  style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}} </span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="service" :options="get_porters_service_service_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Day" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Day" field="end_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','porter_service') && !check_user_service_operator"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','porter_service') && !check_user_service_operator" >
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_porters_extra_service(slotProps)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </DataTable>
                    </div>
                    <div v-if="$ability.can('add','porter_service') && !check_user_service_operator" class="row mt-5">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-text p-button-rounded" icon="fa fa-plus" @click="add_porters_extra_service_shift(true)" style="height: 2.5rem; width: 2.5rem;" /> 
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <InputNumber v-model="new_porters_extra_service_shift.quantity"/>
                                <label for="quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label"> 
                                <Dropdown id="contact" :options="get_porters_service_service_options" v-model="new_porters_extra_service_shift.service_type" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex;align-items:center;">
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','porter_service_service_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_porters_service_service_option(slotProps.option.id)"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','porter_service_service_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center my-auto">
                                                    <InputText v-model="new_porters_option_service" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_porters_service_service_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Service </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.start_day" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day</label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.start_shift" :timeOnly="true" @change="new_porters_extra_service_shift.start_shift = num2time($event)"/>
                                <label for="start_turn"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.end_day" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day</label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.end_shift" :timeOnly="true" @change="new_porters_extra_service_shift.end_shift = num2time($event)"/>
                                <label for="start_turn"> End  </label>
                            </span>
                        </div>
                    </div>
                    <div v-if="get_error_porters_service_extra_services.length > 0" class="col p-error" style="color: red;">
                        <div v-for="error in get_error_porters_service_extra_services" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.service_type">Service type: {{error.service_type[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                                <li v-if="error">{{error.service_sheet}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Final" :disabled="porters_service.status != 'Confirmed'">
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        <DataTable :value="get_porters_service_extra_services_final" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" v-model:editingRows="editing_rows_porters" @row-edit-save="onRowEditSavePorters">
                            <Column header="Quantity" field="quantity" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Service" field="id_service_option" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}} </span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="service" :options="get_porters_service_service_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Day" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Day" field="end_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime_hour" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field]}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true"  hourFormat="24"  @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','porter_service')"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','porter_service')" >
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_porters_extra_service(slotProps)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </DataTable>
                    </div>
                    <div v-if="$ability.can('add','porter_service')" class="row">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-text p-button-rounded" icon="fa fa-plus" @click="add_porters_extra_service_shift(false)" style="height: 2.5rem; width: 2.5rem;" /> 
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <InputNumber v-model="new_porters_extra_service_shift.quantity"/>
                                <label for="quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label"> 
                                <Dropdown id="contact" :options="get_porters_service_service_options" v-model="new_porters_extra_service_shift.service_type" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex;align-items:center;">
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','porter_service_service_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_porters_service_service_option(slotProps.option.id)"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','porter_service_service_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center my-auto">
                                                    <InputText v-model="new_porters_option_service" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_porters_service_service_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Service </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.start_day" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day</label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.start_shift" :timeOnly="true" @change="new_porters_extra_service_shift.start_shift = num2time($event)"/>
                                <label for="start_turn"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.end_day" :minDate="get_min_date" :maxDate="get_max_date" dateFormat="yy-mm-dd"/>
                                <label for="start_turn"> Day</label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_porters_extra_service_shift.end_shift" :timeOnly="true" @change="new_porters_extra_service_shift.end_shift = num2time($event)"/>
                                <label for="start_turn"> End  </label>
                            </span>
                        </div>
                    </div>
                    <div v-if="get_error_porters_service_extra_services.length > 0" class="col p-error">
                        <div v-for="error in get_error_porters_service_extra_services" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.service_type">Service type: {{error.service_type[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date:{{error.end_datetime[0]}}</li>
                                <li v-if="error">{{error.service_sheet}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
            <div class="row">
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5 mb-3">
                        <div class="inline-flex align-items-center">
                            <b> Mail Note </b>
                            <i class="fa fa-circle-info pl-2" v-tooltip="'Please remember that this section is meant for adding a note to the email content, it\'s not the main body of the email.'" type="text" placeholder="Right" style="cursor:pointer;"></i>
                        </div>
                    </Divider>
                    <div class="row pt-2 pb-2">
                        <div class="col">
                            <TextArea placeholder="This text will be attached to the email sent." rows="5" v-model="porters_service.mail_text" :disabled="!$ability.can('change','porter') || check_user_service_operator"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5 mb-3">
                        <div class="inline-flex align-items-center">
                            <b> Additional Notes </b>
                        </div>
                    </Divider>
                    <div class="row pt-2 pb-2">
                        <div class="col">
                            <TextArea rows="5" v-model="porters_service.note_info" :disabled="!$ability.can('change','porter') || check_user_service_operator"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="disabled_buttons == false">
                <div v-if="porters_service.status === 'Modified' || porters_service.status === 'Refused' || porters_service.status === 'Confirmed' || porters_service.status === 'On hold'" class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','porter') && porters_service.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_porters_service()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_porters') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text " @click="dialog_confirmation_porters_mail()"/>
                    </div>
                    <span v-if="porters_service.status && $ability.can('change','porter') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_porters_service()"/>
                    </span>
                </div>
                <div v-else class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','porter') && porters_service.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_porters_service()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_porters') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_porters_mail()"/>
                    </div>
                    <div v-if="$ability.can('change','porter') || $ability.can('change','porter_service') || $ability.can('add','porter') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <span v-if="porters_service.status && $ability.can('change','porter') && !check_user_service_operator">
                            <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_porters_service()"/>
                        </span>
                        <span v-else-if="!porters_service.status && $ability.can('add','porter') && !check_user_service_operator">
                            <Button label="Create" icon="fa-solid fa-plus" class="p-button-raised p-button-text p-button-success" @click="save_changes_porters_service()"/>
                        </span>
                    </div>
                </div>
            </div>    
            <div v-if="mail_porters_error">
                <div v-if="mail_porters_error != ''" class="row pt-3">
                    <div class="col text-center" style="color: red;">
                        <span v-if="mail_porters_error['service_sheet']">{{mail_porters_error['service_sheet']}}</span>
                        <span v-else> {{mail_porters_error}} </span>
                    </div>
                </div>
            </div>
        </div>
    </Panel>

    <Dialog v-model:visible="delete_service" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span> Are you sure you want to delete this service? </span>
        </div>
        <template #footer>
            <Button label="No"  class="p-button-text" @click="delete_service = false"/>
            <Button label="Yes" @click="handle_function_delete(service_selected)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>

</template>

<script>
import Panel from 'primevue/panel'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import TextArea from 'primevue/textarea'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Dialog from 'primevue/dialog'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Message from 'primevue/message'
import InputText from 'primevue/inputtext'

import ModalSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/ModalSupplier_responsibles.vue/'

import { num2time, num2timeedit } from '@/modules/utilities.js'

import ability from '../../../services/ability'


export default {
    name: 'Porters',
    components: {
        Panel,
        Dropdown,
        Button,
        TextArea,
        Divider,
        Calendar,
        InputNumber,
        Dialog,
        TabPanel,
        TabView,
        DataTable,
        Column,
        Message,
        InputText,
        ModalSupplierResponsibles
    },
    props: {
        service_sheet: {
            type: Object
        },
        all_suppliers: {
            type: Object
        },
    },
    data(){
        return {
            color: localStorage.getItem('primary_color'),
            porters_service: {'id_supplier_responsible': null, 'mail_text':'', 'info_note':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))},
            new_porters_extra_service_shift: {'porter':'', 'service_type': '', 'quantity': null, 'start_day':'', 'start_shift': '', 'end_day':'', 'end_shift': ''},
            new_porters_service_shift: {'porter':'', 'shift_type': '', 'passengers_quantity':null ,'start_day':'', 'start_shift': '', 'end_day':'', 'end_shift': ''},
            shift_types:[{"name":"Embarking"}, {"name":"Disembarking"}],
            porters_shift_services:[],
            porters_service_extra_services: [],
            porters_service_extra_services_estimated: [],
            porters_service_extra_services_final: [],
            selected_porters_shifts: [],   // Schedules from porters services selected with the checkbox,
            service_type_porters: [
                'Forklift',
                'Conveyor Belt',
                'Ship Loaders'
            ],
            mail_porters_error: null,
            porters_service_responsibles_list: [],
            delete_service: false,
            porters_collapsed: true,
            modal_confirmation_mail: false,
            editing_rows_porters: [],
            editing_rows_shift_porters: [],
            new_porters_option_service: '',
            created_automatically:false,
            disabled_buttons: false
        }
    },
    computed: {
        get_porters_service: function() {
            return this.$store.getters.get_porters_service
        },
        get_porters_service_service_options: function() {
            return this.$store.getters.get_porters_service_service_options
        },
        get_send_porters_mail: function() {
            return this.$store.getters.get_send_porters_mail
        },
        get_error_porters_service_extra_services: function() {
            return this.$store.getters.get_error_porters_service_extra_services
        },
        get_error_porters_shift_services: function() {
            return this.$store.getters.get_error_porters_shift_services
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        get_porters_service_extra_services_estimated: function() {
            return this.$store.getters.get_porters_service_extra_services_estimated
        },
        get_porters_shift_services: function() {
            return this.$store.getters.get_porters_shift_services
        },
        // get_porters_shift_services: function() {
        //     return this.$store.getters.get_porters_shift_services
        // },
        get_porters_service_extra_services_final: function() {
            return this.$store.getters.get_porters_service_extra_services_final
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        get_error_porters_service_service_options: function() {
            return this.$store.getters.error_porters_service_service_options
        },
        get_min_date: function() {
            return this.$store.getters.min_date
        },
        get_max_date: function() {
            return this.$store.getters.max_date
        }
    },
    async mounted(){
        if(this.get_porters_service.length > 0) {
            if(ability.can('view','porter_service')) {
                await this.$store.dispatch('load_porters_service_extra_services', (Number(this.$route.params.sid)))
                await this.$store.dispatch('load_porters_shift_services', (Number(this.$route.params.sid)))
                this.porters_service = this.get_porters_service[0]
                this.porters_service_extra_services_estimated = this.get_porters_service_extra_services_estimated
                this.porters_service_extra_services_final = this.get_porters_service_extra_services_final
                this.porters_shift_services = this.get_porters_shift_services
                
                if(this.porters_shift_services) {
                    for(var y in this.porters_shift_services) {
                        if(this.porters_shift_services[y].start_datetime) {
                            this.porters_shift_services[y].start_datetime_hour =  this.porters_shift_services[y].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_shift_services[y].start_datetime.split('T')[1].split(':')[1]
                            this.porters_shift_services[y].start_datetime = this.porters_shift_services[y].start_datetime.split('T')[0]
                            this.porters_shift_services[y].end_datetime_hour =  this.porters_shift_services[y].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_shift_services[y].end_datetime.split('T')[1].split(':')[1]
                            this.porters_shift_services[y].end_datetime = this.porters_shift_services[y].end_datetime.split('T')[0]
                        }   
                    }
                }
                
                if(this.porters_service_extra_services_estimated) {
                    for(var x in this.porters_service_extra_services_estimated) {
                        if(this.porters_service_extra_services_estimated[x].start_datetime) {
                            this.porters_service_extra_services_estimated[x].start_datetime_hour =  this.porters_service_extra_services_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_service_extra_services_estimated[x].start_datetime.split('T')[1].split(':')[1]
                            this.porters_service_extra_services_estimated[x].start_datetime = this.porters_service_extra_services_estimated[x].start_datetime.split('T')[0]
                            this.porters_service_extra_services_estimated[x].end_datetime_hour =  this.porters_service_extra_services_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_service_extra_services_estimated[x].end_datetime.split('T')[1].split(':')[1]
                            this.porters_service_extra_services_estimated[x].end_datetime = this.porters_service_extra_services_estimated[x].end_datetime.split('T')[0]
                        }   
                    }
                }

                
                if(this.porters_service_extra_services_final) {
                    for(var i in this.porters_service_extra_services_final) {
                        if(this.porters_service_extra_services_final[i].start_datetime) {
                            this.porters_service_extra_services_final[i].start_datetime_hour =  this.porters_service_extra_services_final[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_service_extra_services_final[i].start_datetime.split('T')[1].split(':')[1]
                            this.porters_service_extra_services_final[i].start_datetime = this.porters_service_extra_services_final[i].start_datetime.split('T')[0]
                            this.porters_service_extra_services_final[i].end_datetime_hour =  this.porters_service_extra_services_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_service_extra_services_final[i].end_datetime.split('T')[1].split(':')[1]
                            this.porters_service_extra_services_final[i].end_datetime = this.porters_service_extra_services_final[i].end_datetime.split('T')[0]
                        }   
                    }
                }
            }
        } else {
            await this.$store.dispatch('empty_porters_extra_services')
        }

        this.mail_porters_error = ''
        this.new_porters_service_shift =  {'porter':'', 'shift_type': '', 'passengers_quantity':null, 'start_datetime': '', 'start_datetime_hour': '', 'end_datetime':'', 'end_datetime_hour': ''}
        this.new_porters_extra_service_shift = {'porter':'', 'service_type': '', 'quantity': null, 'start_datetime': '', 'start_datetime_hour': '', 'end_datetime':'', 'end_datetime_hour': ''}
   
    },
    methods: {
        num2time(data) {
            return num2time(data)
        },
        handleTabChange(event) {
            const selectedTabIndex = event.index // El índice de la pestaña seleccionada
            if (selectedTabIndex === 1) { // Si es la segunda pestaña (índice 1)
                this.disabled_buttons = true
            } else {
                this.disabled_buttons = false
            }
        },
        async fill_shifts(){
            await this.$store.dispatch('load_porters_service_extra_services', (Number(this.$route.params.sid)))
            await this.$store.dispatch('load_porters_shift_services', (Number(this.$route.params.sid)))
            this.porters_service = this.get_porters_service[0]
            this.porters_service_extra_services_estimated = this.get_porters_service_extra_services_estimated
            this.porters_service_extra_services_final = this.get_porters_service_extra_services_final

            this.porters_shift_services = this.get_porters_shift_services
                
            if(this.porters_shift_services.length >= 1 ) {
                for(var y in this.porters_shift_services) {
                    if(this.porters_shift_services[y].start_datetime) {
                        this.porters_shift_services[y].start_datetime_hour =  this.porters_shift_services[y].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_shift_services[y].start_datetime.split('T')[1].split(':')[1]
                        this.porters_shift_services[y].start_datetime = this.porters_shift_services[y].start_datetime.split('T')[0]
                        this.porters_shift_services[y].end_datetime_hour =  this.porters_shift_services[y].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_shift_services[y].end_datetime.split('T')[1].split(':')[1]
                        this.porters_shift_services[y].end_datetime = this.porters_shift_services[y].end_datetime.split('T')[0]
                    }   
                }
            }
            
            if(this.porters_service_extra_services_estimated.length >= 1) {
                for(var x in this.porters_service_extra_services_estimated) {
                    if(this.porters_service_extra_services_estimated[x].start_datetime) {
                        this.porters_service_extra_services_estimated[x].start_datetime_hour =  this.porters_service_extra_services_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_service_extra_services_estimated[x].start_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_estimated[x].start_datetime = this.porters_service_extra_services_estimated[x].start_datetime.split('T')[0]
                        this.porters_service_extra_services_estimated[x].end_datetime_hour =  this.porters_service_extra_services_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_service_extra_services_estimated[x].end_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_estimated[x].end_datetime = this.porters_service_extra_services_estimated[x].end_datetime.split('T')[0]
                    }   
                }
            }

            
            if(this.porters_service_extra_services_final.length >= 1) {
                for(var i in this.porters_service_extra_services_final) {
                    if(this.porters_service_extra_services_final[i].start_datetime) {
                        this.porters_service_extra_services_final[i].start_datetime_hour =  this.porters_service_extra_services_final[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_service_extra_services_final[i].start_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_final[i].start_datetime = this.porters_service_extra_services_final[i].start_datetime.split('T')[0]
                        this.porters_service_extra_services_final[i].end_datetime_hour =  this.porters_service_extra_services_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_service_extra_services_final[i].end_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_final[i].end_datetime = this.porters_service_extra_services_final[i].end_datetime.split('T')[0]
                    }   
                }
            }
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },

        // Default Services
        async add_porters_shift(){
            this.mail_porters_error = ''
            // Checks if there is no porters service created first
            if(this.get_porters_service.length == 0){
                await this.$store.dispatch('create_porters_service', this.porters_service)
                await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
                this.porters_service = this.get_porters_service[0]
            }

            this.new_porters_service_shift.porter = this.get_porters_service[0].id
            await this.$store.dispatch('create_porters_shift_services', this.new_porters_service_shift)
            this.new_porters_service_shift = {'porter':'', 'service': '', 'passengers_quantity':null,'shift_type': '', 'start_datetime_hour': '', 'end_datetime':'', 'end_datetime_hour': ''}

            await this.$store.dispatch('load_porters_shift_services', (Number(this.$route.params.sid)))
            this.porters_shift_services = this.get_porters_shift_services
            this.porters_service_extra_services_final = this.get_porters_service_extra_services_final

            this.porters_shift_services = this.get_porters_shift_services
                
                if(this.porters_shift_services) {
                    for(var y in this.porters_shift_services) {
                        if(this.porters_shift_services[y].start_datetime) {
                            this.porters_shift_services[y].start_datetime_hour =  this.porters_shift_services[y].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_shift_services[y].start_datetime.split('T')[1].split(':')[1]
                            this.porters_shift_services[y].start_datetime = this.porters_shift_services[y].start_datetime.split('T')[0]
                            this.porters_shift_services[y].end_datetime_hour =  this.porters_shift_services[y].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_shift_services[y].end_datetime.split('T')[1].split(':')[1]
                            this.porters_shift_services[y].end_datetime = this.porters_shift_services[y].end_datetime.split('T')[0]
                        }   
                    }
                }


            await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
            this.porters_service = this.get_porters_service[0]
        },

        // Extra Services
        async add_porters_extra_service_shift(data){
            this.mail_porters_error = ''
            // Checks if there is no porters service created first
            if(this.get_porters_service.length == 0){
                await this.$store.dispatch('create_porters_service', this.porters_service)
                await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
                this.porters_service = this.get_porters_service[0]
            }

            this.new_porters_extra_service_shift.is_estimated = data
            this.new_porters_extra_service_shift.porter = this.get_porters_service[0].id

            await this.$store.dispatch('create_porters_service_extra_services', this.new_porters_extra_service_shift)
            this.new_porters_extra_service_shift = {'porter':'', 'service': '', 'quantity': null, 'start_datetime':'', 'start_datetime_hour': '', 'end_datetime':'', 'end_datetime_hour': ''}

            await this.$store.dispatch('load_porters_service_extra_services', (Number(this.$route.params.sid)))
            this.porters_service_extra_services_estimated = this.get_porters_service_extra_services_estimated
            this.porters_service_extra_services_final = this.get_porters_service_extra_services_final

            if(this.porters_service_extra_services_estimated) {
                for(var x in this.porters_service_extra_services_estimated) {
                    if(this.porters_service_extra_services_estimated[x].start_datetime) {
                        this.porters_service_extra_services_estimated[x].start_datetime_hour =  this.porters_service_extra_services_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_service_extra_services_estimated[x].start_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_estimated[x].start_datetime = this.porters_service_extra_services_estimated[x].start_datetime.split('T')[0]
                        this.porters_service_extra_services_estimated[x].end_datetime_hour =  this.porters_service_extra_services_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_service_extra_services_estimated[x].end_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_estimated[x].end_datetime = this.porters_service_extra_services_estimated[x].end_datetime.split('T')[0]
                    }   
                }
            }


            if(this.porters_service_extra_services_final) {
                for(var i in this.porters_service_extra_services_final) {
                    if(this.porters_service_extra_services_final[i].start_datetime) {
                        this.porters_service_extra_services_final[i].start_datetime_hour =  this.porters_service_extra_services_final[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.porters_service_extra_services_final[i].start_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_final[i].start_datetime = this.porters_service_extra_services_final[i].start_datetime.split('T')[0]
                        this.porters_service_extra_services_final[i].end_datetime_hour =  this.porters_service_extra_services_final[i].end_datetime.split('T')[1].split(':')[0] + ':' + this.porters_service_extra_services_final[i].end_datetime.split('T')[1].split(':')[1]
                        this.porters_service_extra_services_final[i].end_datetime = this.porters_service_extra_services_final[i].end_datetime.split('T')[0]
                    }   
                }
            }

            await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
            this.porters_service = this.get_porters_service[0]
        },
        async add_porters_service_service_option() {
            await this.$store.dispatch('create_porters_service_service_option', this.new_porters_option_service)
            if(this.get_error_porters_service_service_options.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Option created successfully', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_porters_service_service_options.name[0], life: 3000});
            }
            this.new_porters_option_service = ''
        },
        async fill_porters_service(e) {
            if(e!=null){
                if(e.value === false || this.porters_collapsed === false){
                    if (this.porters_service.status === undefined){
                        await this.$store.dispatch('create_past_porters', (Number(this.$route.params.sid)))
                        if(this.get_porters_service[0] !== undefined){
                            this.porters_service = this.get_porters_service[0]
                            this.fill_shifts()
                            this.created_automatically=true
                        }
                        
                    }
                }
            }
            await this.$store.dispatch('load_port_supplier_service')

            for(var item in this.get_supplier_service) {
                if(this.get_supplier_service[item].service == 'Porters') {
                    this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[item].id_supplier.id)
                }
            }

            this.porters_service_responsibles_list = this.get_supplier_service_responsible

            if(this.get_porters_service.length == 0) {
                for(var index in this.get_supplier_service){
                    if(this.get_supplier_service[index].service == 'Porters') {
                        this.porters_service.id_supplier = this.get_supplier_service[index].id_supplier
                        this.porters_service.id_supplier_responsible = this.get_supplier_service[index].id_supplier_responsible
                    }
                }
            }

            this.load_porters_responsibles()
        },
        async onRowEditSavePorters(event) {
            await this.$store.dispatch('clean_errors_porters_extra_services')
            let { newData } = event;
            await this.$store.dispatch('update_porters_service_extra_services', newData)
            await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
            this.porters_service = this.get_porters_service[0]

            if(this.get_error_porters_service_extra_services.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Porters service updated successfully', life: 3000});
            }

        },
        async onRowEditSavePortersShifts(event) {
            await this.$store.dispatch('clean_errors_porters_extra_services')
            let { newData } = event;
            await this.$store.dispatch('update_porters_shift_services', newData)
            await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
            this.porters_service = this.get_porters_service[0]

            if(this.get_error_porters_shift_services.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Porters service shift updated successfully', life: 3000});
            }

        },
        async load_porters_responsibles() {

            await this.$store.dispatch('load_supplier_service_responsible', this.porters_service.id_supplier.id)
            this.porters_service_responsibles_list = this.get_supplier_service_responsible

            if(this.porters_service_responsibles_list.length != 0) {
                this.porters_service.id_supplier_responsible = this.porters_service_responsibles_list[0]
            } else {
                this.porters_service.id_supplier_responsible = ''
            }
        },
        change_porters_service() {
            this.mail_porters_error = null
        }, 
        async save_changes_porters_service() {
            this.mail_porters_error = ''

            if(this.get_porters_service.length == 0) {
                await this.$store.dispatch('create_porters_service', this.porters_service)
                await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
                this.porters_service = this.get_porters_service[0]

                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Porters service created successfully', life: 3000});
            } else {
                await this.$store.dispatch('update_porters_service', this.porters_service)
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Porters service updated successfully', life: 3000});
            }

            if(this.get_porters_service.length != 0) {
                await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
                this.porters_service = this.get_porters_service[0]
            }
            
        },
        async delete_porters_shift(event) {

            await this.$store.dispatch('delete_porters_shift_service', event.data.id)

            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shift deleted', life: 3000});

            await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
            this.porters_service = this.get_porters_service[0]

        },

        async delete_porters_extra_service(event) {

            await this.$store.dispatch('delete_porters_service', event.data.id)

            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shift deleted', life: 3000});

            await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
            this.porters_service = this.get_porters_service[0]

        },
        async delete_porters_service_service_option(id) {
            await this.$store.dispatch('delete_porters_service_service_option', id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Option removed', life: 3000});
        },
        async dialog_confirmation_porters_mail() {
            this.modal_confirmation_mail = true
            this.mail_selected = 'send_porters_service_mail'
        },
        async send_porters_service_mail() {
            
            this.$store.dispatch('clean_errors_porters_extra_services')
            this.mail_porters_error = ''
            
            await this.$store.dispatch('update_porters_service', this.porters_service)

            if(this.mail_porters_error == '' && this.get_error_porters_service_extra_services.length == 0) {

                if(this.porters_service.status == 'None' || this.porters_service.status == 'Modified' || this.porters_service.status == 'Refused') {
                    await this.$store.dispatch('send_porters_service_mail', (Number(this.$route.params.sid)), this.porters_service)
                    this.mail_porters_error = this.get_error_porters_service_extra_services
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                } else {
                    if(this.get_send_porters_mail == false) {
                        this.mail_porters_error = 'Cannot send email with the same data as the previous.' 
                    } else {
                        await this.$store.dispatch('send_porters_service_mail', (Number(this.$route.params.sid)), this.porters_service)
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                    }
                }
                
                if(this.get_porters_service.length != 0) {
                    await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
                    this.porters_service = this.get_porters_service[0]
                }
            }

        },
        async delete_porters_service() {
            this.service_selected = 'delete_porters_service_confirmation'
            this.delete_service = true
        },
        async delete_porters_service_confirmation() {

            this.$store.dispatch('clean_errors_porters_extra_services')
            this.mail_porters_error = ''
            
            await this.$store.dispatch('delete_porters', this.porters_service.id)
            await this.$store.dispatch('load_porters_service', (Number(this.$route.params.sid)))
            
            this.porters_service = {'id_supplier_responsible': '', 'mail_text':'', 'info_note':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))}
            this.porters_service_extra_services = []
            this.porters_shift_services = []
            
            if(this.get_error_porters_service_extra_services.detail === 'Not found.') {
                this.mail_porters_error = 'Cannot delete empty service.'
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Service deleted', life: 3000})
            }
            await this.$store.dispatch('empty_porters_extra_services')
            await this.$store.dispatch('empty_porters_shift_services')
            this.fill_porters_service()
        },
        async porters_change_supplier() {
            await this.$store.dispatch('update_porters_service', this.porters_service)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Service updated', life: 3000})
        },
        handle_function_delete(function_name){
            this[function_name]()
            this.delete_service = false
            this.service_selected = ''
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
    }

}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
    border-radius: 0%;
}

.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}


:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>