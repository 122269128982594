<template>
    <Panel header="Terminals Security" v-model:collapsed="terminals_collapsed" :collapsed="true" :toggleable="true"  @toggle="fill_terminals_security_service($event)">
        <template #header>
            <div @click="terminals_collapsed = !terminals_collapsed; fill_terminals_security_service($event)" class="col" style="padding: 0.7rem;">
                <div class="row"  >
                    <div class="col">
                        <span class="title_panels"> Terminals Security </span>
                    </div>
                </div>
            </div>
        </template>
        <template #icons>
            <i v-if="created_automatically" v-tooltip.left="'This service was created automatically with previous data.'" class="fa-solid fa-circle-info fa-xl" style="color: #fcc203;"></i>    
            <i v-if="terminals_security_service.status" class="p-panel-header-icon mr-2">
                <span v-if="terminals_security_service.status === 'None'">
                    <span v-tooltip.left="'Service email not sent.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: #002d7d;"></span>
                </span>
                <span v-if="terminals_security_service.status === 'Modified'">
                    <span v-tooltip.left="'Service modified after email.'" class="fa-solid fa-circle-half-stroke fa-xl" style="color: orange;"></span>
                </span>
                <span v-if="terminals_security_service.status === 'On hold'">
                    <span v-tooltip.left="'Service email sent and waiting for confirmation.'" class="fa-solid fa-circle fa-xl" style="color: orange;"></span>
                </span>
                <span v-if="terminals_security_service.status === 'Confirmed'">
                    <span v-tooltip.left="'Service email confirmed.'" class="fa-solid fa-circle fa-xl" style="color: #22C55E;"></span>
                </span>
                <span v-if="terminals_security_service.status === 'Refused'">
                    <span v-tooltip.left="'Service email refused.'" class="fa-solid fa-circle fa-xl" style="color: #EF4444;"></span>
                </span>
            </i>
        </template>
        <div class="container-fluid p-fluid">
            <div class="row">
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Supplier </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="all_suppliers" :disabled="!$ability.can('change','terminal_security') || check_user_service_operator" :filter="true" optionLabel="name" v-model="terminals_security_service.id_supplier" @change="load_terminals_security_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <Divider align="center">
                        <div class="inline-flex align-items-center">
                            <b> Responsible </b>
                        </div>
                    </Divider>
                    <div class="row pt-3 pb-3">
                        <div class="col">
                            <span class="p-float-label">
                                <Dropdown :options="terminals_security_service_responsibles_list" placeholder="Select a responsible..." :disabled="!$ability.can('change','terminal_security') || check_user_service_operator" :filter="true" optionLabel="name" v-model="terminals_security_service.id_supplier_responsible" @click="load_terminals_security_responsibles()">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value">
                                            <span>{{slotProps.value.name}}</span>
                                        </div>
                                        <div v-else>
                                            <span> {{slotProps.placeholder}} </span>
                                        </div>
                                    </template>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$ability.can('add','supplier_responsible')" class="row justify-content-end">
                <div class="col-12 md:col-3 text-center">
                    <ModalSupplierResponsibles :service_sheet="true"/>
                </div>
            </div>
            <Divider align="center" class="mt-2 mb-3">
                <div class="inline-flex align-items-center">
                    <b> Shifts </b>
                </div>
            </Divider>
            <TabView @tab-click="handleTabChange">
                <TabPanel header="Estimation">
                    <div v-if="terminals_security_service.status == 'Pending'">
                        <Message :closable="false" severity="info"> The email of this service has already been sent, if any modifications are made, the previous email will be annulled. </Message>
                    </div>
                    <div v-if="terminals_security_service.status == 'Confirmed'">
                        <Message :closable="false" severity="info"> This service has already been confirmed. If any modifications are made, the status will change, and the previous email will be annulled. </Message>
                    </div>
                    <div v-if="get_terminals_security_service_shifts_estimated.length > 0" class="row justify-content-end">
                        <div class="col text-end">
                            <span> <i class="fa-solid fa-circle" style="color: rgba(0,0,0,.15)"></i> Extra </span>
                        </div>
                    </div>
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        <Datatable :value="get_terminals_security_service_shifts_estimated" tableStyle="min-width: 70rem" editMode="row" class="mt-3" dataKey="id" :rowClass="rowClass" v-model:editingRows="editing_rows_terminals" @row-edit-save="onRowEditSaveTerminals">
                            <Column field="is_extra">
                                <template #body>
                                    
                                </template>
                                <template #editor="{ data, field }">
                                    <InputSwitch v-model="data[field]" />
                                </template>
                            </Column>
                            <Column header="Type" field="id_security_type" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="contact" :options="get_terminal_security_service_type_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Quantity" field="quantity" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Position" field="id_position_option" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="contact" :options="get_terminal_security_service_position_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                </template>
                            </Column>
                            <Column header="Hour" field="start_datetime_hour" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true" hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                </template>
                            </Column>
                            <Column header="Hour" field="end_datetime_hour" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true" hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','terminal_security_service') && !check_user_service_operator"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','terminal_security_service') && !check_user_service_operator">
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_terminal_service_shift(slotProps)"/>
                                </template>
                            </Column>
                            <Column v-if="$ability.can('add','incidence_report') && !check_user_service_operator" header="Incidence">
                                <template #body="slotProps">
                                    <Button icon="fa fa-plus" class="p-button-rounded p-button-text p-button-plain" @click="open_modal_incidence_terminal_security(slotProps.data)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </Datatable>
                    </div>
                    <div v-if="$ability.can('add','terminal_security_service') && !check_user_service_operator" class="row mt-5">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-text p-button-rounded" icon="fa fa-plus" @click="add_terminals_security_shift(true)" /> 
                        </div>
                        <div class="col-12 md:col text-center">
                            <div class="row">
                                <small> Extra </small>
                                <span class="text-center"> <InputSwitch v-model="new_terminals_security_service_shifts.extra"/> </span>
                            </div>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Dropdown id="contact" :options="get_terminal_security_service_type_options" v-model="new_terminals_security_service_shifts.service_type" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex;align-items:center;">
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','terminal_security_service_type_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_terminal_security_service_type_option(slotProps.option.id)"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','terminal_security_service_type_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center my-auto">
                                                    <InputText v-model="new_terminal_security_option_type" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_terminal_security_service_type_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Type </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <InputNumber id="k9_quantity" allowEmpty v-model="new_terminals_security_service_shifts.quantity"/>
                                <label for="k9_quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Dropdown id="contact" :options="get_terminal_security_service_position_options" v-model="new_terminals_security_service_shifts.position" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex; align-items:center;">
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','terminal_security_service_position_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_terminal_security_service_position_option(slotProps.option.id)"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','terminal_security_service_position_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center my-auto">
                                                    <InputText v-model="new_terminal_security_option_position" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_terminal_security_service_position_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Position </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_terminals_security_service_shifts.start_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_terminals_security_service_shifts.start_datetime_hour" :timeOnly="true"  hourFormat="24" @change="new_terminals_security_service_shifts.start_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> Hour </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_terminals_security_service_shifts.end_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> End </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_terminals_security_service_shifts.end_datetime_hour" :timeOnly="true"  hourFormat="24" @change="new_terminals_security_service_shifts.end_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> Hour </label>
                            </span>
                        </div>
                    </div>  
                    <div v-if="get_error_terminal_security_service_shifts.length > 0" class="col p-error">
                        <div v-for="error in get_error_terminal_security_service_shifts" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.service_type">Service type: {{error.service_type[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Final" :disabled="terminals_security_service.status != 'Confirmed'">  
                    <div v-if="get_terminals_security_service_shifts_final.length > 0" class="row justify-content-end">
                        <div class="col text-end">
                            <span> <i class="fa-solid fa-circle" style="color: rgba(0,0,0,.15)"></i> Extra </span>
                        </div>
                    </div>
                    <div class="p-0 ms-3" style="max-width: calc(100vw - 10rem)">
                        <Datatable :value="get_terminals_security_service_shifts_final" tableStyle="min-width: 70rem" editMode="row" dataKey="id" class="mt-3" :rowClass="rowClass" v-model:editingRows="editing_rows_terminals" @row-edit-save="onRowEditSaveTerminals">
                            <Column field="is_extra">
                                <template #body>
                                    
                                </template>
                                <template #editor="{ data, field }">
                                    <InputSwitch v-model="data[field]" />
                                </template>
                            </Column>
                            <Column header="Type" field="id_security_type" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="contact" :options="get_terminal_security_service_type_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Quantity" field="quantity" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <InputNumber v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column header="Position" field="id_position_option" style="width:30rem">
                                <template #body="{data,field}">
                                    <span> {{data[field].name}}</span>
                                </template>
                                <template #editor="{ data, field }">
                                    <Dropdown id="contact" :options="get_terminal_security_service_position_options" v-model="data[field].id" optionLabel="name" optionValue="id"/>
                                </template>
                            </Column>
                            <Column header="Start" field="start_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                </template>
                            </Column>
                            <Column header="Hour" field="start_datetime_hour" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true" hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="End" field="end_datetime" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" dateFormat="yy-mm-dd" :minDate="get_min_date" :maxDate="get_max_date"/>
                                </template>
                            </Column>
                            <Column header="Hour" field="end_datetime_hour" style="width:30rem">
                                <template #editor="{ data, field }">
                                    <Calendar autocomplete="off" v-model="data[field]" :timeOnly="true" hourFormat="24" @change="data[field] = num2timeedit($event)"/>
                                </template>
                            </Column>
                            <Column header="Edit" :rowEditor="true" v-if="$ability.can('change','terminal_security_service')"></Column>
                            <Column header="Delete" v-if="$ability.can('delete','terminal_security_service')">
                                <template #body="slotProps">
                                    <Button class="p-button-rounded p-button-text p-button-plain" icon="fa fa-trash" @click="delete_terminal_service_shift(slotProps)"/>
                                </template>
                            </Column>
                            <Column v-if="$ability.can('add','incidence_report')" header="Incidence">
                                <template #body="slotProps">
                                    <Button icon="fa fa-plus" class="p-button-rounded p-button-text p-button-plain" @click="open_modal_incidence_terminal_security(slotProps.data)"/>
                                </template>
                            </Column>
                            <template #empty>
                                <div class="row">
                                    <div class="col text-center">
                                        No records.
                                    </div>
                                </div>
                            </template>
                        </Datatable>
                    </div>
                    <div v-if="$ability.can('add','terminal_security_service')" class="row mt-5">
                        <div class="col-12 md:col-1 text-center mt-1">
                            <Button class="p-button-raised p-button-text p-button-rounded" icon="fa fa-plus" @click="add_terminals_security_shift(false)" /> 
                        </div>
                        <div class="col-12 md:col text-center">
                            <div class="row">
                                <small> Extra </small>
                                <span class="text-center"> <InputSwitch v-model="new_terminals_security_service_shifts.extra"/> </span>
                            </div>
                        </div>
                        <div class="col-12 md:col-2 mt-2">
                            <span class="p-float-label">
                                <Dropdown id="contact" :options="get_terminal_security_service_type_options" v-model="new_terminals_security_service_shifts.service_type" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex;align-items:center;" >
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','terminal_security_service_type_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_terminal_security_service_type_option(slotProps.option.id)"> </i>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','terminal_security_service_type_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center">
                                                    <InputText v-model="new_terminal_security_option_type" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success p-button-raised p-button-rounded p-button-sm" @click="add_terminal_security_service_type_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Type </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <InputNumber id="k9_quantity" allowEmpty v-model="new_terminals_security_service_shifts.quantity"/>
                                <label for="k9_quantity"> Quantity </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Dropdown id="contact" :options="get_terminal_security_service_position_options" v-model="new_terminals_security_service_shifts.position" optionValue="id" optionLabel="name">
                                    <template #option="slotProps">
                                        <div class="row">
                                            <div class="col" style="display:flex; align-items:center;">
                                                <span> {{slotProps.option.name}} </span>
                                            </div>
                                            <div v-if="$ability.can('delete','terminal_security_service_type_option')" class="col text-end">
                                                <i class="fa fa-xmark" @click="delete_terminal_security_service_position_option(slotProps.option.id)"/>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="$ability.can('add','terminal_security_service_type_option')" #footer>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-center my-auto">
                                                    <InputText v-model="new_terminal_security_option_position" class="p-inputtext-sm"/>
                                                </div>
                                                <div class="col">
                                                    <Button icon="fa fa-plus" class="p-button-text p-button-success  p-button-rounded p-button-sm" @click="add_terminal_security_service_position_option"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="contact"> Position </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_terminals_security_service_shifts.start_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> Start </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_terminals_security_service_shifts.start_datetime_hour" :timeOnly="true"  hourFormat="24" @change="new_terminals_security_service_shifts.start_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> Hour </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="contact" dateFormat="yy-mm-dd" v-model="new_terminals_security_service_shifts.end_datetime" :minDate="get_min_date" :maxDate="get_max_date"/>
                                <label for="contact"> End </label>
                            </span>
                        </div>
                        <div class="col-12 md:col mt-2">
                            <span class="p-float-label">
                                <Calendar autocomplete="off" id="start_turn" v-model="new_terminals_security_service_shifts.end_datetime_hour" :timeOnly="true"  hourFormat="24" @change="new_terminals_security_service_shifts.end_datetime_hour = num2time($event)"/>
                                <label for="start_turn"> Hour </label>
                            </span>
                        </div>
                    </div>  
                    <div v-if="get_error_terminal_security_service_shifts.length > 0" class="col p-error">
                        <div v-for="error in get_error_terminal_security_service_shifts" :key="error">
                            <ul>    
                                <li v-if="error.quantity">Quantity: {{error.quantity[0]}}</li>
                                <li v-if="error.service_type">Service type: {{error.service_type[0]}}</li>
                                <li v-if="error.start_datetime">Start date: {{error.start_datetime[0]}}</li>
                                <li v-if="error.end_datetime">End date: {{error.end_datetime[0]}}</li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
            <div v-if="$ability.can('change','terminal_security_service')" class="row justify-content-center mt-3">
                <div class="col-12 md:col-2">
                    <Button label="Show Incidences" icon="fa fa-eye" class="p-button-raised p-button-text p-button-warning" @click="open_incidences_terminal_security"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5">
                        <div class="inline-flex align-items-center">
                            <b> Mail Note </b>
                            <i class="fa fa-circle-info pl-2" v-tooltip="'Please remember that this section is meant for adding a note to the email content, it\'s not the main body of the email.'" type="text" placeholder="Right" style="cursor:pointer;"></i>
                        </div>
                    </Divider>
                    <div class="row pt-3">
                        <div class="col">
                            <TextArea placeholder="This text will be attached to the email sent." rows="5" v-model="terminals_security_service.mail_text" :disabled="!$ability.can('change','terminal_security') || check_user_service_operator"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <Divider align="center" class="mt-5">
                        <div class="inline-flex align-items-center">
                            <b> Additional Notes </b>
                        </div>
                    </Divider>
                    <div class="row pt-3">
                        <div class="col">
                            <TextArea rows="5" v-model="terminals_security_service.note_info" :disabled="!$ability.can('change','terminal_security')" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="disabled_buttons == false">
                <div v-if="terminals_security_service.status === 'Modified' || terminals_security_service.status === 'Refused' || terminals_security_service.status === 'Confirmed' || terminals_security_service.status === 'On hold'" class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','terminal_security') && terminals_security_service.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_terminal_security_service()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_terminal_security') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_terminal_security_mail()"/>
                    </div>
                    <span v-if="terminals_security_service.status && $ability.can('change','terminal_security') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_terminal_security_service()"/>
                    </span>
                </div>
                <div v-else class="row pt-3 justify-content-between">
                    <div v-if="$ability.can('delete','terminal_security') && terminals_security_service.status && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Delete Service" icon="fa-solid fa-trash" class="p-button-raised p-button-text p-button-danger" @click="delete_terminal_security_service()"/>
                    </div>
                    <div v-if="$ability.can('send','mail_terminal_security') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <Button label="Send" icon="fa-solid fa-paper-plane" class="p-button-raised p-button-text" @click="dialog_confirmation_terminal_security_mail()"/>
                    </div>
                    <div v-if="$ability.can('change','terminal_security') || $ability.can('change','terminal_security_service') || $ability.can('add','terminal_security') && !check_user_service_operator" class="col-12 md:col-3 lg:col-2">
                        <span v-if="terminals_security_service.status && $ability.can('change','terminal_security') && !check_user_service_operator">
                            <Button label="Save" icon="fa-solid fa-check" class="p-button-raised p-button-text p-button-success" @click="save_changes_terminal_security_service()"/>
                        </span>
                        <span v-else-if="!terminals_security_service.status && $ability.can('add','terminal_security') && !check_user_service_operator">
                            <Button label="Create" icon="fa-solid fa-plus" class="p-button-raised p-button-text p-button-success" @click="save_changes_terminal_security_service()"/>
                        </span>
                    </div>
                </div>
            </div>
            <span v-if="mail_terminal_error">
                <div v-if="mail_terminal_error.length > 0" class="row pt-3 text-center">
                    <div class="col" style="color: red;">
                        {{mail_terminal_error}}
                    </div>
                </div>
            </span>
        </div>
    </Panel>

    <Dialog v-model:visible="delete_service" :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span> Are you sure you want to delete this service? </span>
        </div>
        <template #footer>
            <Button label="No"  class="p-button-text" @click="delete_service = false"/>
            <Button label="Yes" @click="handle_function_delete(service_selected)" />
        </template>
    </Dialog>

    <Dialog header="Terminal Security Incidences" v-model:visible="displayModal_incidences_terminal_security" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '70vw'}">
        <Datatable :value="terminal_security_incidences" class="p-datatable-customers"
            dataKey="id"  responsiveLayout="scroll">
            <Column header="Title" field="title" style="min-width:12rem">
                <template #body="{data}">
                    {{data.title}}
                </template>
            </Column>
            <Column header="Description" field="text" style="min-width:12rem">
                <template #body="{data}">
                    {{data.text}}
                </template>
            </Column>
            <Column header="Date" filterField="date" dataType="date" style="min-width:12rem">
                <template #body="{data}">
                    {{format_date(data.date)}}
                </template>
            </Column>
            <Column header="Subcategory" field="id_incidence_subcategory.title" style="min-width:12rem">
                <template #body="slotProps">
                    {{slotProps.data.id_incidence_subcategory.title}} <br>
                </template>
            </Column>
            <Column header="User" field="id_user.username" style="min-width:12rem"/>
            <template #empty>
                <div class="row">
                    <div class="col text-center">
                        No incidences.
                    </div>
                </div>
            </template>
        </Datatable>    
        <template #footer>
            <Button label="Close" @click="close_modal_terminal_security_incidences_show" class="p-button-text"/>
        </template>
    </Dialog> 

    <Dialog header="New Incidence" v-model:visible="displayModal_terminal_security" :modal="true" :breakpoints="{'960px': '90vw'}" :style="{width: '60vw'}">
        <ModalIncidences :service_sheet="service_sheet" :terminal_security="true" :service_incidence="service_incidence"/>
        <template #footer>
            <Button label="Close" @click="close_modal_terminal_security" class="p-button-text" />
        </template>
    </Dialog>

    <Dialog v-model:visible="modal_confirmation_mail"  :style="{width: '35rem'}" header="Confirm" :modal="true">
        <div class="row">
            <div class="col">
                <span> Are you sure you want to send this mail? </span>
            </div>
        </div>
        <template #footer>
            <Button label="No" class="p-button-text" @click="modal_confirmation_mail = false"/>
            <Button label="Yes" @click="handle_function_confirmation_mail(mail_selected)" />
        </template>
    </Dialog>

</template>

<script>
import Panel from 'primevue/panel'
import Datatable from 'primevue/datatable'
import Column from 'primevue/column'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import Calendar from 'primevue/calendar'
import Divider from 'primevue/divider'
import TextArea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import Dialog from 'primevue/dialog'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Message from 'primevue/message'

import ModalIncidences from '@/components/Incidences/ModalIncidences.vue'
import ModalSupplierResponsibles from '@/components/AdminDashboard/Supplier_responsibles/ModalSupplier_responsibles.vue/'

import { num2time, num2timeedit } from '@/modules/utilities.js'

export default {
    name: 'Terminal Security',
    components: {
        Datatable,
        Column,
        InputSwitch,
        Dropdown,
        Button,
        InputNumber,
        Calendar,
        Divider,
        TextArea,
        InputText,
        Panel,
        Dialog,
        TabView,
        TabPanel,
        Message,
        ModalIncidences,
        ModalSupplierResponsibles
    },
    props: {
        service_sheet: {
            type: Object
        },
        all_suppliers: {
            type: Object
        },
    },
    data() {
        return {
            color: localStorage.getItem('primary_color'),
            terminals_security_service: {'id_supplier_responsible': null, 'note_info':'', 'mail_text':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))},
            new_terminals_security_service_shifts: {'terminal_security':'', 'extra':false, 'position': '','service_type': '', 'quantity': null, 'start_datetime':'', 'start_datetime_hour': '', 'end_datetime': '', 'end_datetime_hour': ''},
            terminals_security_service_shifts: [],
            terminals_security_service_shifts_estimated: [],
            terminals_security_service_shifts_final: [],
            selected_terminals_service: [],
            terminal_service_type: [
                'Security guard',
                'Security assistant'
            ],
            mail_terminal_error: null,
            terminals_security_service_responsibles_list: [],
            editing_rows_terminals: [],
            displayModal_terminal_security: false,
            service_incidence: {},
            displayModal_incidences_terminal_security: false,
            new_terminal_security_option_type: '',
            new_terminal_security_option_position: '',
            terminals_collapsed: true,
            delete_service: false,
            terminal_security_incidences: [],
            modal_confirmation_mail: false,
            created_automatically:false,
            disabled_buttons: false,
        }
    },
    computed: {
        get_terminals_security_service: function() {
            return this.$store.getters.get_terminals_security_service
        },
        get_terminals_security_service_shifts: function() {
            return this.$store.getters.get_terminals_security_service_shifts
        },
        get_send_terminal_security_mail: function() {
            return this.$store.getters.get_send_terminal_security_mail
        },
        get_error_terminal_security_service_shifts: function () {
            return this.$store.getters.get_error_terminal_security_service_shifts
        },
        get_terminal_security_service_type_options: function() {
            return this.$store.getters.get_terminal_security_service_type_options
        },
        get_terminal_security_service_position_options: function() {
            return this.$store.getters.get_terminal_security_service_position_options
        },
        get_supplier_service: function() {
            return this.$store.getters.get_supplier_service
        },
        get_supplier_service_responsible: function() {
            return this.$store.getters.get_supplier_service_responsible
        },
        service_sheet_incidences: function() {
            return this.$store.getters.service_sheet_incidences
        },
        get_terminals_security_service_shifts_estimated: function() {
            return this.$store.getters.get_terminals_security_service_shifts_estimated
        },
        get_terminals_security_service_shifts_final: function() {
            return this.$store.getters.get_terminals_security_service_shifts_final
        },
        get_current_user: function() {
            return this.$store.getters.get_current_user
        },
        check_user_service_operator: function(){
            for(var index in this.get_current_user.groups) {
                if(this.get_current_user.groups[index].name == 'Ops') {
                    return true
                }
            }
            return false
        },
        get_error_terminal_security_service_type_option: function() {
            return this.$store.getters.error_terminal_security_service_type_option
        },
        get_error_terminal_security_service_position_option: function() {
            return this.$store.getters.error_terminal_security_service_position_option
        },
        get_min_date: function() {
            return this.$store.getters.min_date
        },
        get_max_date: function() {
            return this.$store.getters.max_date
        }
    },
    async mounted() {
        if(this.get_terminals_security_service.length > 0) {
            await this.$store.dispatch('load_terminals_security_service_shifts', (Number(this.$route.params.sid)))
            this.terminals_security_service = this.get_terminals_security_service[0]

            this.terminals_security_service_shifts_estimated = this.get_terminals_security_service_shifts_estimated
            this.terminals_security_service_shifts_final = this.get_terminals_security_service_shifts_final

            if(this.terminals_security_service_shifts_estimated) {
                for(var p in this.terminals_security_service_shifts_estimated) {
                    this.terminals_security_service_shifts_estimated[p].start_datetime_hour =  this.terminals_security_service_shifts_estimated[p].start_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_estimated[p].start_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_estimated[p].start_datetime =  this.terminals_security_service_shifts_estimated[p].start_datetime.split('T')[0]
                    this.terminals_security_service_shifts_estimated[p].end_datetime_hour =  this.terminals_security_service_shifts_estimated[p].end_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_estimated[p].end_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_estimated[p].end_datetime =  this.terminals_security_service_shifts_estimated[p].end_datetime.split('T')[0]
                }
            }

            this.terminals_security_service_shifts_final = this.get_terminals_security_service_shifts_final
            
            if(this.terminals_security_service_shifts_final) {
                for(var q in this.terminals_security_service_shifts_final) {
                    this.terminals_security_service_shifts_final[q].start_datetime_hour =  this.terminals_security_service_shifts_final[q].start_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_final[q].start_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_final[q].start_datetime =  this.terminals_security_service_shifts_final[q].start_datetime.split('T')[0]
                    this.terminals_security_service_shifts_final[q].end_datetime_hour =  this.terminals_security_service_shifts_final[q].end_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_final[q].end_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_final[q].end_datetime =  this.terminals_security_service_shifts_final[q].end_datetime.split('T')[0]
                }
            }
        } else {
            await this.$store.dispatch('empty_terminals_shifts')
        }

        this.mail_terminal_error = ''
        this.new_terminals_security_service_shifts = {'terminal_security':'', 'extra':false, 'position': '','service_type': '', 'quantity': null, 'start_datetime': '', 'end_datetime': ''}
    
        this.service_sheet_incidences.map(ss_incidence => {
            if(ss_incidence.id_incidence_category.title == 'Terminal Security Service') {
                this.terminal_security_incidences.push(ss_incidence)
            }
        })
    },
    methods: {
        async close_modal_terminal_security() {
            this.terminal_security_incidences = []
            this.displayModal_terminal_security = false;
            await this.$store.dispatch('load_incidence_by_service_sheet', (Number(this.$route.params.sid)))
            this.service_sheet_incidences.map(ss_incidence => {
                if(ss_incidence.id_incidence_category.title == 'Terminal Security Service') {
                    this.terminal_security_incidences.push(ss_incidence)
                }
            })
        },
        async fill_shifts(){
            await this.$store.dispatch('load_terminals_security_service_shifts', (Number(this.$route.params.sid)))
            this.terminals_security_service = this.get_terminals_security_service[0]

            this.terminals_security_service_shifts_estimated = this.get_terminals_security_service_shifts_estimated
            this.terminals_security_service_shifts_final = this.get_terminals_security_service_shifts_final

            if(this.terminals_security_service_shifts_estimated) {
                for(var p in this.terminals_security_service_shifts_estimated) {
                    this.terminals_security_service_shifts_estimated[p].start_datetime_hour =  this.terminals_security_service_shifts_estimated[p].start_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_estimated[p].start_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_estimated[p].start_datetime =  this.terminals_security_service_shifts_estimated[p].start_datetime.split('T')[0]
                    this.terminals_security_service_shifts_estimated[p].end_datetime_hour =  this.terminals_security_service_shifts_estimated[p].end_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_estimated[p].end_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_estimated[p].end_datetime =  this.terminals_security_service_shifts_estimated[p].end_datetime.split('T')[0]
                }
            }

            this.terminals_security_service_shifts_final = this.get_terminals_security_service_shifts_final
            
            if(this.terminals_security_service_shifts_final) {
                for(var q in this.terminals_security_service_shifts_final) {
                    this.terminals_security_service_shifts_final[q].start_datetime_hour =  this.terminals_security_service_shifts_final[q].start_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_final[q].start_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_final[q].start_datetime =  this.terminals_security_service_shifts_final[q].start_datetime.split('T')[0]
                    this.terminals_security_service_shifts_final[q].end_datetime_hour =  this.terminals_security_service_shifts_final[q].end_datetime.split('T')[1].split(':')[0] + ':' + this.terminals_security_service_shifts_final[q].end_datetime.split('T')[1].split(':')[1]
                    this.terminals_security_service_shifts_final[q].end_datetime =  this.terminals_security_service_shifts_final[q].end_datetime.split('T')[0]
                }
            }
        },
        handleTabChange(event) {
            const selectedTabIndex = event.index // El índice de la pestaña seleccionada
            if (selectedTabIndex === 1) { // Si es la segunda pestaña (índice 1)
                this.disabled_buttons = true
            } else {
                this.disabled_buttons = false
            }
        },
        close_modal_terminal_security_incidences_show() {
            this.displayModal_incidences_terminal_security = false;
        },
        open_modal_incidence_terminal_security(event) {
            this.displayModal_terminal_security = true;
            this.service_incidence = event
        },
        open_incidences_terminal_security() {
            this.displayModal_incidences_terminal_security = true
        },
        async delete_terminal_security_service_type_option(id) {
            await this.$store.dispatch('delete_terminal_security_service_type_option', id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Option removed', life: 3000});
        },
        async delete_terminal_security_service_position_option(id) {
            await this.$store.dispatch('delete_terminal_security_service_position_option', id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Option removed', life: 3000});
        },
        async add_terminal_security_service_type_option() {
            await this.$store.dispatch('create_terminals_security_service_type_option', this.new_terminal_security_option_type)
            if(this.get_error_terminal_security_service_type_option.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Option created successfully', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_terminal_security_service_type_option.name[0], life: 3000});
            }
            this.new_terminal_security_option_type = ''
        },
        async add_terminal_security_service_position_option() {
            await this.$store.dispatch('create_terminals_security_service_position_option', this.new_terminal_security_option_position)
            if(this.get_error_terminal_security_service_position_option.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Option created successfully', life: 3000});
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'error', summary: 'Error', detail: this.get_error_terminal_security_service_position_option.name[0], life: 3000});
            }
            this.new_terminal_security_option_position = ''
        },
        async fill_terminals_security_service(e) {
            if (e!=null){
                if(e.value === false || this.terminals_collapsed === false){
                    if (this.terminals_security_service.status === undefined){
                        await this.$store.dispatch('create_past_terminal_security', (Number(this.$route.params.sid)))
                        if(this.get_terminals_security_service[0] !== undefined){
                            this.terminals_security_service  = this.get_terminals_security_service[0]
                            this.fill_shifts()
                            this.created_automatically=true
                        }
                    }
                }
            }

            await this.$store.dispatch('load_port_supplier_service')

            for(var item in this.get_supplier_service) {
                if(this.get_supplier_service[item].service == 'Terminal Security') {
                    this.$store.dispatch('load_supplier_service_responsible', this.get_supplier_service[item].id_supplier.id)
                }
            }
            
            this.terminals_security_service_responsibles_list = this.get_supplier_service_responsible

            if(this.get_terminals_security_service.length == 0) {
                for(var index in this.get_supplier_service){
                    if(this.get_supplier_service[index].service == 'Terminal security') {
                        this.terminals_security_service.id_supplier = this.get_supplier_service[index].id_supplier
                        this.terminals_security_service.id_supplier_responsible = this.get_supplier_service[index].id_supplier_responsible
                    }
                }
            }

            this.load_terminals_security_responsibles()
        },
        rowClass(data) {
            return data.is_extra === true ? 'row-extra': null;
        },
        async onRowEditSaveTerminals(event) {

            await this.$store.dispatch('clean_errors_terminals_security_shifts')
            let { newData } = event;
            await this.$store.dispatch('update_terminals_security_service_shifts', newData)
            await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
            this.terminals_security_service = this.get_terminals_security_service[0]

            if(this.get_error_terminal_security_service_shifts.length == 0) {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Terminal service updated successfully', life: 3000});
            }

        },
        async load_terminals_security_responsibles() {

            await this.$store.dispatch('load_supplier_service_responsible', this.terminals_security_service.id_supplier.id)
            this.terminals_security_service_responsibles_list = this.get_supplier_service_responsible
            
            if(this.terminals_security_service_responsibles_list.length != 0) {
                this.terminals_security_service.id_supplier_responsible = this.terminals_security_service_responsibles_list[0]
            } else {
                this.terminals_security_service.id_supplier_responsible = ''
            }

        },
        async delete_terminal_service_shift(event) {       

            await this.$store.dispatch('delete_terminal_security_service', event.data.id)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Shift/s deleted', life: 3000});
            await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
            this.terminals_security_service = this.get_terminals_security_service[0]
        },
        async save_changes_terminal_security_service() {
            this.mail_terminal_error = ''
            
            if(this.get_terminals_security_service.length == 0) {
                    await this.$store.dispatch('create_terminals_security_service', this.terminals_security_service)
                    await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
                    this.terminals_security_service = this.get_terminals_security_service[0]

                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Terminal service created successfully', life: 3000});
                } else {
                    await this.$store.dispatch('update_terminals_security_service', this.terminals_security_service)
                    this.$toast.removeAllGroups();
                    this.$toast.add({severity:'success', summary: 'Successful', detail:'Terminal service updated successfully', life: 3000});
                }

                if(this.get_terminals_security_service != 0) {
                    await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
                    this.terminals_security_service = this.get_terminals_security_service[0]
                }

        },
        async dialog_confirmation_terminal_security_mail() {
            this.modal_confirmation_mail = true
            this.mail_selected = 'send_terminal_security_service_mail'
        },
        async send_terminal_security_service_mail() {
            this.mail_terminal_error = ''

            await this.$store.dispatch('update_terminals_security_service', this.terminals_security_service)

            if(this.mail_terminal_error == '') {
                if(this.get_terminals_security_service_shifts.length > 0) {
                    if(this.terminals_security_service.status == 'None' || this.terminals_security_service.status == 'Modified' || this.terminals_security_service.status == 'Refused') {
                        await this.$store.dispatch('send_terminal_security_service_mail', (Number(this.$route.params.sid)), this.terminals_security_service)
                        this.mail_terminal_error = this.get_error_terminal_security_service_shifts
                        this.$toast.removeAllGroups();
                        this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                    } else {
                        if(this.get_send_terminal_security_mail == false) {
                            this.mail_terminal_error = 'Cannot send mail with the same data as the previous.' 
                        } else {
                            await this.$store.dispatch('send_terminal_security_service_mail', (Number(this.$route.params.sid)), this.terminals_security_service)
                            this.$toast.removeAllGroups();
                            this.$toast.add({severity:'success', summary: 'Successful', detail:'Email sent successfully', life: 3000});
                        }
                    }
                } else {
                    this.mail_terminal_error = 'Cannot send mail without any shift. Please make sure to add at least one.'
                }

                if(this.get_terminals_security_service.length != 0) {
                    await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
                    this.terminals_security_service = this.get_terminals_security_service[0]
                }
                
            }
            
        },
        async delete_terminal_security_service(){
            this.service_selected = 'delete_terminal_security_service_confirmation'
            this.delete_service = true
        },
        async delete_terminal_security_service_confirmation() {
            this.mail_terminal_error = ''

            await this.$store.dispatch('delete_terminal_security', this.terminals_security_service.id)
            await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
            
            this.terminals_security_service = {'id_supplier_responsible': '', 'note_info':'', 'mail_text':'', 'id_supplier': 66, 'id_service_sheet': (Number(this.$route.params.sid))}
            this.terminals_security_service_shifts = []
            
            if(this.get_error_terminal_security_service_shifts.detail === 'Not found.') {
                this.mail_terminal_error = 'Cannot delete empty service.'
            } else {
                this.$toast.removeAllGroups();
                this.$toast.add({severity:'success', summary: 'Successful', detail:'Service deleted', life: 3000})
            }
            await this.$store.dispatch('empty_terminals_shifts')
            this.fill_terminals_security_service()
        },
        async terminal_security_change_supplier() {
            await this.$store.dispatch('update_terminals_security_service', this.terminals_security_service)
            this.$toast.removeAllGroups();
            this.$toast.add({severity:'success', summary: 'Successful', detail:'Service updated', life: 3000})
        },
        async handle_function_delete(function_name){
            this[function_name]()
            this.delete_service = false
            this.service_selected = ''
        },
        async add_terminals_security_shift(data) {
            this.mail_terminal_error = ''

            if(this.get_terminals_security_service.length == 0){
                await this.$store.dispatch('create_terminals_security_service', this.terminals_security_service)
                await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
                this.terminals_security_service = this.get_terminals_security_service[0]
            }

            this.new_terminals_security_service_shifts.is_estimated = data
            this.new_terminals_security_service_shifts.terminal_security = this.get_terminals_security_service[0].id

            await this.$store.dispatch('create_terminals_security_service_shifts', this.new_terminals_security_service_shifts)
            this.new_terminals_security_service_shifts = {'terminal_security':'', 'service_type': '', 'quantity': null, 'start_datetime': '', 'end_datetime': ''}

            //await this.$store.dispatch('load_terminals_security_service_shifts', (Number(this.$route.params.sid)))
            //this.terminals_security_service_shifts_estimated = this.get_terminals_security_service_shifts_estimated
            //this.terminals_security_service_shifts_final = this.get_terminals_security_service_shifts_final

            /*
            if(this.terminals_security_service_shifts_estimated) {
                for(var x in this.terminals_security_service_shifts_estimated) {
                    if(this.terminals_security_service_shifts_estimated[x].start_datetime) {
                        this.terminals_security_service_shifts_estimated[x].start_datetime_hour = this.terminals_security_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[0] + ':' +  this.terminals_security_service_shifts_estimated[x].start_datetime.split('T')[1].split(':')[1]
                        this.terminals_security_service_shifts_estimated[x].start_datetime =  this.terminals_security_service_shifts_estimated[x].start_datetime.split('T')[0] 
                        this.terminals_security_service_shifts_estimated[x].end_datetime_hour = this.terminals_security_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[0] + ':' +  this.terminals_security_service_shifts_estimated[x].end_datetime.split('T')[1].split(':')[1]
                        this.terminals_security_service_shifts_estimated[x].end_datetime =  this.terminals_security_service_shifts_estimated[x].end_datetime.split('T')[0] 
                    }   
                }
            }
            
            if(this.terminals_security_service_shifts_final) {
                for(var i in this.terminals_security_service_shifts_final) {
                    if(this.terminals_security_service_shifts_final[i].start_datetime) {
                        this.terminals_security_service_shifts_final[i].start_datetime_hour = this.terminals_security_service_shifts_final[i].start_datetime.split('T')[1].split(':')[0] + ':' +  this.terminals_security_service_shifts_final[i].start_datetime.split('T')[1].split(':')[1]
                        this.terminals_security_service_shifts_final[i].start_datetime =  this.terminals_security_service_shifts_final[i].start_datetime.split('T')[0] 
                        this.terminals_security_service_shifts_final[i].end_datetime_hour = this.terminals_security_service_shifts_final[i].end_datetime.split('T')[1].split(':')[0] + ':' +  this.terminals_security_service_shifts_final[i].end_datetime.split('T')[1].split(':')[1]
                        this.terminals_security_service_shifts_final[i].end_datetime =  this.terminals_security_service_shifts_final[i].end_datetime.split('T')[0] 
                    }   
                }
            }
            */

            await this.$store.dispatch('load_terminals_security_service', (Number(this.$route.params.sid)))
            this.terminals_security_service = this.get_terminals_security_service[0]
        },
        num2time(data) {
            return num2time(data)
        },
        num2timeedit(data) {
            return num2timeedit(data)
        },
        handle_function_confirmation_mail(function_name){
            this[function_name]()
            this.modal_confirmation_mail = false
            this.mail_selected = ''
        },
    }
}
</script>

<style scoped>
:deep(.row-extra) {
    background-color: rgba(0,0,0,.15) !important;
    border-radius: 20px !important;
}
.card {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 1rem;
    border-radius: 0%;
}

.on_hold{
    border: 4px solid #002D72;
    border-radius: 10px;
}

.p-panel{
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-panel:deep(.p-panel-header){
    background: v-bind(color) !important;
    cursor: pointer;
}

.p-panel:deep(.p-panel-header .p-panel-title){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon){
    color: white;
}

.p-panel:deep(.p-panel-header .p-panel-header-icon:enabled:hover){
    background: v-bind(color) !important;
}

.p-divider.p-divider-horizontal {
  color: v-bind(color);
}

.p-tooltip{
    background: v-bind(color);
}

ul {
    list-style-type: none;
}

.title_panels{
    color: white; 
    font-weight: 700; 
    padding: 0.6rem;
}

:deep(.p-panel-header) {
    padding: 0 !important;
}

:deep(.p-panel-icons) {
    margin-right: .7rem;
}


:deep(.p-button-sm){
    padding: .5px .5px;
}

:deep(.p-scrollpanel-content) {
    overflow-y: hidden;
}
</style>